import { ChangeEvent, ChangeEventHandler, PropsWithoutRef, ReactElement, useState } from "react";
import TextField from '@mui/material/TextField';
import { isAddress } from 'ethers'
import _ from "lodash";

export type AddressSearchBarProps = PropsWithoutRef<Readonly<{
    triggerSearch: (error: boolean, s: string) => void
}>>

const AddressSearchBar = (props: AddressSearchBarProps): ReactElement<typeof TextField> => {
    const [error, setError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')

    const searchOrError: ChangeEventHandler<HTMLInputElement> = (val: ChangeEvent<HTMLInputElement>): void => {
        setError(false)
        setErrorMessage('')
        const searchVal = val.target.value
        const addressValid = isAddress(searchVal)
        setError(!addressValid)
        setErrorMessage(addressValid ? '' : 'Invalid Ethereum Address, please check target')
        props.triggerSearch(addressValid, searchVal)
    }

    const debouncedSearchOrError = _.debounce(searchOrError, 1000)
    
    return(<TextField 
        className='address-search'
        id='send-screen-address-search'
        error={error}
        onChange={debouncedSearchOrError}
        helperText={errorMessage}
        placeholder='Wallet addess to send to'>
    </TextField>)
};

export {AddressSearchBar};