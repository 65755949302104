import { PropsWithoutRef, JSX } from "react";
import { InnerDataBox } from "../InnerDataBox/InnerDataBox";
import { StaticAnalysisRiskAccordion } from "../StaticAnalysisRiskAccordion/StaticAnalysisRiskAccordion";

type Detector = Readonly<{
    impact: 'High' | 'Medium' | 'Low' | 'Informational',
    check: string
}>

export type StaticAnalysisProps = PropsWithoutRef<Readonly<{
    staticAnalysis: null | Readonly<{
        error: null | string,
        success: boolean,
        results: Readonly<{
            detectors: ReadonlyArray<Detector>
        }>
    }>
}>>

type BaseStaticAnalysisProps = PropsWithoutRef<Readonly<{
    hasReentrancy: boolean,
    hasSuicidal: boolean,
    modifyingStorage: boolean,
    arbitrarySend: boolean,
    protectedVars: boolean,
    otherFlaws: ReadonlyArray<Detector>
}>>

const renderOtherFlaws = (otherFlaws: boolean): JSX.Element => {
    if (otherFlaws === false) {
        return <></>
    } else {
        return <span>Other High Risk Flaws: <span data-testid="other-vuln" className='fail'></span></span>
    }
}

const BaseStaticAnalysisView = (props: BaseStaticAnalysisProps): JSX.Element => {
    return (
        <InnerDataBox xs={12} md={6} wrapperClass='static-high-risk'>
            <h6>High Risk Details</h6>
            <StaticAnalysisRiskAccordion 
                title='Re-entrancy Vulnerability:'
                accordionId='reentrancy-accordion'
                riskStatusId='reentrancy-vuln'
                passCondition={props.hasReentrancy === false}
                baseContent='Re-entrancy allows attackers to repeatedly call a function and drain funds before state changes occur. This can lead to assets being stolen.'
            />
            <StaticAnalysisRiskAccordion 
                title='Suicidal Calls:'
                accordionId='suicidal-accordion'
                riskStatusId='suicidal-vuln'
                passCondition={props.hasSuicidal === false}
                baseContent='Suicidal Calls allow irreversibly destroying the contract, potentially "rugging" users by removing access to funds permanently.'
            />
            <StaticAnalysisRiskAccordion 
                title='Modifying Storage:'
                accordionId='modifying-storage-accordion'
                riskStatusId='modifying-vuln'
                passCondition={props.modifyingStorage === false}
                baseContent='This contract can modify its own state, such as account balances'
            />
            <StaticAnalysisRiskAccordion 
                title='Sending to unknown address:'
                accordionId='unknown-address-accordion'
                riskStatusId='unknown-vuln'
                passCondition={props.arbitrarySend === false}
                baseContent='Assets may be irrecoverable'
            />
            <StaticAnalysisRiskAccordion 
                title='Contract Vars unprotected:'
                accordionId='unprotected-vars-accordion'
                riskStatusId='unprotected-vuln'
                passCondition={props.protectedVars === false}
                baseContent='Anyone can overwrite critical variables, breaking logic or redirecting assets.'
            />
            {renderOtherFlaws(props.otherFlaws.length >0)}
        </InnerDataBox>
    )
}

const StaticAnalysis = (props: StaticAnalysisProps): null | JSX.Element => {
    if ((props.staticAnalysis === null) || (Object.keys(props.staticAnalysis.results).length === 0)) {
        return <BaseStaticAnalysisView 
            hasReentrancy={false}
            hasSuicidal={false}
            modifyingStorage={false}
            arbitrarySend={false}
            protectedVars={false}
            otherFlaws={[]}
        />
    } else if ((props.staticAnalysis?.error !== null) || (props.staticAnalysis?.success === false)) { 
        return (
            <InnerDataBox xs={12} md={6} wrapperClass='static-high-risk'>
                <h6>Unable to read contract.</h6>
                <h5>Possible obfuscated code or language error. Proceed with caution.</h5>
                <span>Static Analysis: <span data-testid="static-anal" className='fail'></span></span>
            </InnerDataBox>
        )
    } else {
        const hasReentrancy = !!props.staticAnalysis.results.detectors.find((d: Detector) => d.check === 'reentrancy-eth')
        const hasSuicidal = !!props.staticAnalysis.results.detectors.find((d: Detector) => d.check === 'suicidal')
        const modifyingStorage = !!props.staticAnalysis.results.detectors.find((d: Detector) => d.check === 'array-by-reference')
        const arbitrarySend = !!props.staticAnalysis.results.detectors.find((d: Detector) => d.check === 'arbitrary-send-erc20')
        const protectedVars = !!props.staticAnalysis.results.detectors.find((d: Detector) => d.check === 'protected-vars')
        const otherFlaws = props.staticAnalysis.results.detectors.filter((d: Detector) => ![
            'reentrancy-eth',
            'suicidal',
            'array-by-reference',
            'arbitrary-send-erc20',
            'protected-vars',
            'solc-version'
        ].includes(d.check) && d.impact === 'High')
        if (hasReentrancy || hasSuicidal || modifyingStorage || arbitrarySend || protectedVars || otherFlaws.length > 0){
            return <BaseStaticAnalysisView 
                hasReentrancy={hasReentrancy}
                hasSuicidal={hasSuicidal}
                modifyingStorage={modifyingStorage}
                arbitrarySend={arbitrarySend}
                protectedVars={protectedVars}
                otherFlaws={otherFlaws}
            />
        } else {
            return null
        }
    }

};

export {StaticAnalysis};