
import {JSX, PropsWithChildren} from 'react'
import {
    TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { Column } from '@devexpress/dx-react-grid';

type Props = PropsWithChildren<Readonly<{column: Column}>>
const AssetGridTableHeaderCell = (props: Props): JSX.Element => {
    const {children, column, ...restProps} = props
    return (
        <TableHeaderRow.Content
            column={column}
            align={'left'}
            {...restProps}
        >{['icon', 'failure'].includes(column.name) ? null : children}</TableHeaderRow.Content>
    )
}
    
export default AssetGridTableHeaderCell