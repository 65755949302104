
import {useState, useEffect, JSX} from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useCopyToClipboard } from 'usehooks-ts';
import {
    Table,
} from '@devexpress/dx-react-grid-material-ui';
import { AssetGridCellProps } from '../../types/AssetGrid/AssetGridCell'
import CircularProgress from '@mui/material/CircularProgress';
import { useWeb3React } from '@web3-react/core';
import ky from 'ky';
import { ReturnedRisk } from '../../types/NetworkTypes/ReturnedAssetRisk';

const AssetGridCell = (props: AssetGridCellProps): JSX.Element => {
    const [risks, setRisks] = useState<ReturnedRisk | null>(null)
    const [riskFetchFailed, setRiskFetchFailed] = useState<boolean>(false)
    const [_priceFetchFailed, _setPriceFetchFailed] = useState<boolean>(false)
    const [_lastPurchasedFailed, _setLastPurchasedFailed] = useState<boolean>(false)
    const [triggerRequery, setTriggerRequery] = useState<boolean>(true)
    const web3React = useWeb3React()
    const [_price, _setPrice] = useState(null)
    const [_lastPurchase, _setLastPurchase] = useState(null)
    const [_value, copy] = useCopyToClipboard()
    const {children, ...restProps} = props

    useEffect(() => {
        const fetchRisks = async () => {
            try {
                const hexChainId = web3React.chainId ? web3React.chainId.toString(16) : '1'
                setRiskFetchFailed(false)
                const response = await ky.post(`${process.env.REACT_APP_FIDESIUM_URL}/api/v0/0x${hexChainId}/contract`, {json: {contract: props.row.address}, timeout: 30000})
                const responseBody = await response.json()
                setRisks(responseBody as ReturnedRisk)
            } catch (e) {
                setRiskFetchFailed(true)
                setRisks(null)
                console.log('error fetching', e)
            }
        }
        if(triggerRequery && ((risks === null) || (riskFetchFailed ===true))) {
            fetchRisks()
        }
        // if(triggerRequery && ((price === null) || (priceFetchFailed ===true))) {
        // }
        // if(triggerRequery && ((lastPurchase === null) || (lastPurchasedFailed ===true))) {
        // }
        setTriggerRequery(false)
    }, [risks, riskFetchFailed, triggerRequery])

    switch (props.column.name) {
    case 'failure':
        return(<Table.Cell {...restProps}>{children}</Table.Cell>)
    case 'icon':
        return (<Table.Cell {...restProps}>
            <img
                src={props.row.icon || undefined}
            ></img></Table.Cell>)
    case 'symbol':
        return(<Table.Cell {...restProps}>{children}</Table.Cell>)
    case 'address':
        return(
            <Table.Cell {...restProps}>
                <div>
                    {props.row.address}
                    <ContentCopyIcon sx={{ display: 'block', fontSize: 14, "&:hover": {cursor: "pointer"} }} onClick={() => copy(props.row.address)}/>
                </div>
            </Table.Cell>)
    case 'rating':
        return(<Table.Cell {...restProps} ><CircularProgress /></Table.Cell>)
    case 'docRating':
        return(<Table.Cell {...restProps} colSpan={0}>{children}</Table.Cell>)
    case 'marketRating':
        return(<Table.Cell {...restProps} colSpan={0}>{children}</Table.Cell>)
    case 'whaleRating':
        return(<Table.Cell {...restProps} colSpan={0}>{children}</Table.Cell>)
    case 'amount':
        return(<Table.Cell {...restProps}>{children}</Table.Cell>)
    case 'price':
        return(<Table.Cell {...restProps} ><CircularProgress /></Table.Cell>)
    case 'value':
        return(<Table.Cell {...restProps} colSpan={0}>{children}</Table.Cell>)
    case 'lastPurchased':
        return(<Table.Cell {...restProps}><CircularProgress /></Table.Cell>)  
    case 'more':
        return(<Table.Cell {...restProps}>{children}</Table.Cell>)
    default:
        return(<Table.Cell {...restProps}>{children}</Table.Cell>)
    }
}
    
export default AssetGridCell