import ky from 'ky'

type Token = Readonly<{symbol: string, address: string, logo: string, possible_spam: boolean, decimals: null | undefined | number, marketCap: number, hasAbi: boolean}>
type TokenList = ReadonlyArray<Readonly<{label: string, value: string, logo: string | null, possible_spam: boolean, decimals: null | undefined | number, marketCap: number, hasAbi: boolean}>>
const fetchTokens = async (contract: string): Promise<TokenList> => {
    try {
        const response = await ky.get(`${process.env.REACT_APP_FIDESIUM_URL}/api/v0/contractlookup/0x1/${contract}`, {timeout: 30000})
        const responseBody: ReadonlyArray<Token> = await response.json()
        const compiledResponse = await Promise.all(responseBody.reduce((memo: TokenList, item: Token) => {
            return [...memo, {label: item.symbol, value: item.address, logo: item.logo, possible_spam: item.possible_spam, decimals: item.decimals, marketCap: item.marketCap, hasAbi: item.hasAbi}]
        }, []))
        if (compiledResponse.length === 0) {
            return [{label: 'No such contract', value: '', logo: null, possible_spam: false, decimals: null, marketCap: 0, hasAbi: false}]
        } else { 
            return compiledResponse
        }
    } catch (err) {
        console.log('err', err)
        return  [{label: 'No such contract', value: '', logo: null, possible_spam: false, decimals: null, marketCap: 0, hasAbi: false}]
    }
}


export { fetchTokens }