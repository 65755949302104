import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import ER from '../assets/fidesium-error.png';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';

const ErrorState = () => {

    const [renavigate, setRenavigate] = useState(false)
    
    useEffect(() => {
        if(renavigate === true) {
            window.location.replace(`${process.env.REACT_APP_URL}`)
            setRenavigate(false)
        }
    }, [renavigate])

    return (
        <Container maxWidth="sm">
            <Grid container className='error-warning' my={2}>
                <Grid item xs={2} className='error-icon'><ReportGmailerrorredIcon sx={{ fontSize: 86 }}/></Grid>
                <Grid item xs={7} className='error-text'>
                    <Box><h2>Computation Failed </h2></Box>
                    <Box><h4>Please be careful. Our engineering team has been notified</h4></Box>
                </Grid>
                <Grid item xs={3} className='error-image'><img className="logo" src={ER} alt="Fidesium logo"/></Grid>
            </Grid>
            <Grid container my={2}>
                <Button variant="contained" color="error" fullWidth={true} size="large" onClick={() => setRenavigate(true)}>Retry</Button>
            </Grid>
        </Container>
    )
}

export default ErrorState