import '../../App.scss';
import {JSX} from 'react'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinearProgress from '@mui/material/LinearProgress'
import { InnerDataBox } from '../../components/InnerDataBox/InnerDataBox'
import { DataBoxWrapper } from '../../components/DataBoxWrapper/DataBoxWrapper';
import { StaticAnalysis } from '../../components/StaticAnalysis/StaticAnalysis'
import { useCopyToClipboard } from 'usehooks-ts'
import { TokenSearchBar } from '../../components/TokenSearchBar/TokenSearchBar';
import { TokenRiskAccordion } from '../../components/TokenRiskAccordion/TokenRiskAccordion';
import { WrappedTokenRiskAccordion } from '../../components/WrappedTokenRiskAccordion/WrappedTokenRiskAccordion';
import { ChartingDisplay } from '../../components/ChartingDisplay/ChartingDisplay';
import moment, { Moment } from 'moment';
import twitter from '../../assets/token-twitter.png';
import telegram from '../../assets/token-telegram.png';
import website from '../../assets/token-website.png';
import github from '../../assets/token-github.png';

const selectRiskClass = (score: number) => {
    if (score <= 10) {
        return 'ten'
    } else if (score <= 20) {
        return 'twenty'
    } else if (score <= 30) {
        return 'thirty'
    } else if (score <= 40) {
        return 'forty'
    } else if (score <= 50) {
        return 'fifty'
    } else if (score <= 60) {
        return 'sixty'
    } else if (score <= 70) {
        return 'seventy'
    } else if (score <= 80) {
        return 'eighty'
    } else {
        return 'ninety'
    }
}

type Detector = Readonly<{
    impact: 'High' | 'Medium' | 'Low',
    check: string
}>

type ReturnedRisk = Readonly<{
    contract: string,
    price: number,
    blockDiff: number,
    cmcMetadata: Readonly<{
        url: string | null,
        twitter: string | null,
        chat: string | null,
        source: string | null,
        description: string | null
    }>,
    fdv: number,
    marketCap: number,
    creator: string,
    creatorSanctioned: boolean,
    contractSanctioned: boolean,
    risks: Readonly<{
        name: string,
        risks: Readonly<{
            whaleRisk: boolean,
            unburnedLiquidityRisk: boolean,
            liquidityRisk: boolean,
            marketCapRisk: boolean,
            deployerWhitelisted: boolean,
            abiRisk: boolean,
            totalRisk: number,
            proxyRisk: boolean
        }>
    }>,
    staticAnalysis: null | Readonly<{
        error: null | string,
        success: boolean,
        results: Readonly<{
            detectors: ReadonlyArray<Detector>
        }>
    }>
}>

const renderTwitter = (currentRisk: ReturnedRisk) => {
    if(currentRisk.cmcMetadata.twitter) {
        return(<>
            <a href={currentRisk.cmcMetadata.twitter} target="_blank" rel="noopener noreferrer">
                <img className="social-image" src={twitter} alt="twitter"/>
            </a>
        </>)
    } else {
        return (<img className="social-image grey" src={twitter} alt="twitter"/>)
    }
}

const renderWebsite = (currentRisk: ReturnedRisk) => {
    if(currentRisk.cmcMetadata.url) {
        return(<>
            <a href={currentRisk.cmcMetadata.url} target="_blank" rel="noopener noreferrer">
                <img className="social-image" src={website} alt="website"/>
            </a>
        </>)
    } else {
        return (<img className="social-image grey" src={website} alt="website"/>)
    }
}

const renderChat = (currentRisk: ReturnedRisk) => {
    if(currentRisk.cmcMetadata.chat) {
        return(<>
            <a href={currentRisk.cmcMetadata.chat} target="_blank" rel="noopener noreferrer">
                <img className="social-image" src={telegram} alt="telegram"/>
            </a>
        </>)
    } else {
        return (<img className="social-image grey" src={telegram} alt="telegram"/>)
    }
}

const renderGithub = (currentRisk: ReturnedRisk) => {
    if(currentRisk.cmcMetadata.source) {
        return(<>
            <a href={currentRisk.cmcMetadata.source} target="_blank" rel="noopener noreferrer">
                <img className="social-image" src={github} alt="github"/>
            </a>
        </>)
    } else {
        return (<img className="social-image grey" src={github} alt="github"/>)
    }
}

const renderDescirption = (currentRisk: ReturnedRisk) => {
    if(currentRisk.cmcMetadata.description) {
        return(<><p className="tokenDescription">{currentRisk.cmcMetadata.description}</p><br/></>)
    } else {
        return(<p className="tokenDescription">No description available at this time. While not in of itself a red flag, please note you should watch this space.</p>)
    }
}

const renderLaunchDate = (currentRisk: ReturnedRisk, time: Moment) => {
    if (currentRisk !== null) {
        return(
            <InnerDataBox xs={6} md={2} wrapperClass='contract'>
                <h6>Launch date</h6>
                <span className="large">{time.format('DD/MM/yyyy')}</span>
            </InnerDataBox>
        )
    } else {
        return null
    }
}

const TokenSearchComponent = (props: Readonly<{
    tokenSearchFunc: (s: string) => void,
    currentRisk: ReturnedRisk | null,
    pricingHistory: Array<Readonly<{value: number | null, time: string}>>
    displayPurchase: boolean,
    purchaseHandler: null | (() => void),
    web3Active: boolean,
    renderLoading: boolean,
    fetchFailed: boolean,
    searchString: string
    }>): JSX.Element => {
    const [_value, copy] = useCopyToClipboard()
    const renderRisks = () => {
        const currentRisk = props.currentRisk
        const time = moment()
        if (currentRisk !== null) {
            time.subtract(currentRisk.blockDiff * 12.2, 'seconds')
        }
        const renderLoading = props.renderLoading
        const fetchFailed = props.fetchFailed
        const purchaseHandler = props.purchaseHandler || (() => null)
        if (fetchFailed === true) {
            return null
        } else if (renderLoading === true) {
            return(<LinearProgress />)
        } else if (currentRisk === null) {
            return null
        } else {
            const documentationRisk = currentRisk.risks.risks.abiRisk || !currentRisk.risks.risks.deployerWhitelisted || !currentRisk.risks.risks.proxyRisk
            const marketInformationRisk = currentRisk.risks.risks.marketCapRisk || 
              currentRisk.risks.risks.liquidityRisk ||
              currentRisk.risks.risks.unburnedLiquidityRisk
            const whaleRisk = currentRisk.risks.risks.whaleRisk
            const contractRisk = currentRisk.staticAnalysis &&
                currentRisk.staticAnalysis.results && 
                currentRisk.staticAnalysis.results.detectors && 
                currentRisk.staticAnalysis.results.detectors.length > 0
            const riskStyle = selectRiskClass(currentRisk.risks.risks.totalRisk)
            const companyRisk = (currentRisk.cmcMetadata.url === null) || (currentRisk.cmcMetadata.source === null)

            // Function to shorten a string with ellipsis in the middle
            const shortenString = (str: string, maxLength: number) => {
                if (str.length <= maxLength) {
                    return str; // If the string is already shorter than the maximum length, return it as is
                }

                // Calculate lengths of the first and last parts of the string
                const prefixLength = Math.ceil((maxLength - 3) / 2); // Length of the first part
                const suffixLength = Math.floor((maxLength - 3) / 2); // Length of the last part

                // Concatenate the first and last parts of the string with ellipsis in the middle
                return `${str.slice(0, prefixLength)}...${str.slice(-suffixLength)}`;
            };
            const roundToThou = (num: number) => {
                return Math.round(num / 1000000);
            };
            return (<>
                <DataBoxWrapper title='Asset' className='asset'>
                    <InnerDataBox xs={12} md={3} wrapperClass='token'>
                        <p>Network: <span className="symbol">ETH</span></p>
                        <p>Symbol: <span className="symbol">{`${currentRisk.risks.name}`}</span></p>
                        <p className="address">
                            <ContentCopyIcon sx={{ fontSize: 14, "&:hover": {cursor: "pointer"} }} onClick={() => copy(currentRisk.contract)}/> <a href={`https://etherscan.io/address/${currentRisk.contract}`} target="_blank" rel="noopener noreferrer">{shortenString(currentRisk.contract, 20)}</a>
                        </p>
                        <p>Price: <span className="symbol">${props.currentRisk?.price}</span></p>
                        <p>Market Cap: <span className="symbol">${props.currentRisk?.marketCap.toFixed(2)}</span></p>
                        <p>FDV: <span className="symbol">${props.currentRisk?.fdv.toFixed(2)}</span></p>

                        {props.displayPurchase ? <p className="purchase">
                            <Button sx={{ display: { xs: 'none', sm: 'block' } }} variant="contained" color="success" size="small" className="prime" onClick={purchaseHandler} fullWidth>{props.web3Active ? 'Purchase' : 'Connect wallet'}</Button>
                        </p> : null}
                        
                    </InnerDataBox>
                    <InnerDataBox xs={12} md={9} wrapperClass='charting'>
                        <ChartingDisplay pricingHistory={props.pricingHistory}></ChartingDisplay>
                    </InnerDataBox>
                </DataBoxWrapper>
                <DataBoxWrapper title='Risk assessment summary' className='risk-summary'>
                    <Grid item xs={4} md={4} className='riskScoreNumber'>
                        <Box className={`riskScore ${riskStyle}-bg`}>
                            <h6>Summary</h6>
                            <span>{currentRisk.risks.risks.totalRisk}</span>
                        </Box>
                    </Grid>
                    <InnerDataBox xs={4} md={4} wrapperClass='data'>
                        <h6>Summary</h6>
                        <span>Market: <span className={marketInformationRisk ? 'fail' : 'pass'} id='market-risk-status'></span></span>
                        <Divider className="listLine"/>
                        <span>Liquidity: <span className={currentRisk.risks.risks.liquidityRisk ? 'fail' : 'pass'} id='liquidity-risk-status'></span></span>
                        <Divider className="listLine"/>
                        <span>Whales: <span className={whaleRisk ? 'fail' : 'pass'} id='whale-risk'></span></span>
                        <Divider className="listLine"/>
                        <span>Company: <span className={companyRisk ? 'fail' : 'pass'} id='company-risk'></span></span>
                    </InnerDataBox>
                    <InnerDataBox xs={4} md={4} wrapperClass='data'>
                        <h6>Summary</h6>
                        <span>Wallet: <span className={documentationRisk ? 'fail' : 'pass'} id='abi-risk-status-summary'></span></span>
                        <Divider className="listLine"/>
                        <span>Contract: <span className={contractRisk ? 'fail' : 'pass'} id='contract-risk'></span></span>
                        <Divider className="listLine"/>
                        <span>AML: <span className={whaleRisk ? 'fail' : 'pass'} id='aml-risk'></span></span>
                    </InnerDataBox>
                </DataBoxWrapper>
                <DataBoxWrapper title='Market information' className='market'>
                    <InnerDataBox xs={6} md={3} wrapperClass='contract'>
                        <h6>Market Cap</h6>
                        <span className="large">${roundToThou(Number(props.currentRisk?.marketCap))} M</span>
                    </InnerDataBox>
                    <InnerDataBox xs={6} md={3} wrapperClass='contract'>
                        <h6>Fully Dilluted Value</h6>
                        <span className="large">${roundToThou(Number(props.currentRisk?.fdv))} M</span>
                    </InnerDataBox>
                    <InnerDataBox xs={12} md={6} wrapperClass='market'>
                        <h6>Market</h6>
                        <WrappedTokenRiskAccordion 
                            accordionId='market-cap-accordion'
                            title='Sufficient Market Cap:'
                            passCondition={!currentRisk.risks.risks.marketCapRisk}
                            riskStatusId='marketcap-risk-status'
                            baseContent={'Transacting in low market cap assets carries higher risks. Evaluating if an asset has reached sufficient market cap for a project\'s needs helps mitigate those risks.'}
                        >
                            {(currentRisk.risks.risks.marketCapRisk) ? 
                                <span>The assets Market Cap is risky</span> :
                                <span>The assets Market Cap is safe</span>
                            }
                        </WrappedTokenRiskAccordion>
                    </InnerDataBox>
                </DataBoxWrapper>
                <DataBoxWrapper title='Liquidity' className='Liquidity'>
                    {/* <InnerDataBox xs={12} md={6} wrapperClass='contract'>
                        <h6>Breakdown</h6>
                        
                        <span className="bar first"> </span>
                        <span className="bar second"> </span>
                        <span className="bar third"> </span>
                        <span className="bar fourth"> </span>

                    </InnerDataBox> */}
                    <InnerDataBox xs={12} md={6} wrapperClass='market'>
                        <h6>Liquidity</h6>
                        <TokenRiskAccordion 
                            accordionId='liquidity-accordion'
                            title='Sufficient liquidity:'
                            passCondition={!currentRisk.risks.risks.liquidityRisk}
                            riskStatusId='liquidity-risk-status'
                            baseContent={'Liquidity facilitates efficient execution, better pricing, and reduces risks when transacting in a crypto asset. Evaluating if liquidity is adequate for intended trade sizes is an important consideration.'}
                        >
                            {(currentRisk.risks.risks.liquidityRisk) ? 
                                <span>Liquidity is low</span> :
                                <span>Liquidity is sufficient</span>
                            }
                        </TokenRiskAccordion>
                    </InnerDataBox>
                    <InnerDataBox xs={12} md={6} wrapperClass='market'>
                        <h6>Burn Amount</h6>
                        <TokenRiskAccordion 
                            accordionId='unburned-liquidity-accordion'
                            title='Sufficient liquidity burned:'
                            passCondition={!currentRisk.risks.risks.unburnedLiquidityRisk}
                            riskStatusId='unburned-liquidity-risk-status'
                            baseContent={'Analyzing the burning strategy and amounts helps gauge community trust, tokenomics sustainability, and deflationary potentials when evaluating crypto assets.'}
                        >
                            {(currentRisk.risks.risks.unburnedLiquidityRisk) ? 
                                <span>Not enough liquidity burned</span> :
                                <span>Enough liquidity burned</span>
                            }
                        </TokenRiskAccordion>
                    </InnerDataBox>
                </DataBoxWrapper>
                <DataBoxWrapper title='Whale distribution' className='whale'>
                    <InnerDataBox xs={12} md={6} wrapperClass='whale'>
                        <h6>Whale distribution</h6>
                        <TokenRiskAccordion 
                            accordionId='whale-count-accordion'
                            title='Number of Whales:'
                            passCondition={!currentRisk.risks.risks.whaleRisk}
                            riskStatusId='whale-numbers'
                            baseContent={'Overall, a higher number of whales with limited individual ownership indicates better distribution and reduces the influence any one whale can exert over the broader market. As with all metrics, analyzing whale counts requires considering the context of the specific asset and its economics.'}
                        >
                            {(currentRisk.risks.risks.whaleRisk) ? 
                                <span>Few whales</span> :
                                <span>Many whales</span>
                            }
                        </TokenRiskAccordion>
                    </InnerDataBox>
                    <InnerDataBox xs={12} md={6} wrapperClass='whale'>
                        <h6>Whale distribution</h6>
                        <TokenRiskAccordion 
                            accordionId='whale-holdings-accordion'
                            title='Distribution of whale holdings:'
                            passCondition={!currentRisk.risks.risks.whaleRisk}
                            riskStatusId='whale-holdings'
                            baseContent={'Evaluating whale concentration relative to distribution across the broader community provides insights into risks, tokenomics, and market psychology around an asset. More even distribution generally signals a healthier asset.'}
                        >
                            {(currentRisk.risks.risks.whaleRisk) ? 
                                <span>Whale holdings are heavily concentrated</span> :
                                <span>Many smaller whales</span>
                            }
                        </TokenRiskAccordion>
                    </InnerDataBox>
                </DataBoxWrapper>
                <DataBoxWrapper title='Company' className='company'>
                    <InnerDataBox xs={3} md={4} wrapperClass='socials'>
                        <h6>Socials</h6>
                        {renderTwitter(currentRisk)}
                        {renderWebsite(currentRisk)}
                        {renderChat(currentRisk)}
                        {renderGithub(currentRisk)}
                    </InnerDataBox>

                    <InnerDataBox xs={9} md={8} wrapperClass='description'>
                        <h6>Description</h6>
                        {renderDescirption(currentRisk)}
                    </InnerDataBox>
                </DataBoxWrapper>
                <DataBoxWrapper title='Wallets' className='wallets hidden'>
                    <InnerDataBox xs={12} md={6} wrapperClass='whale'>
                        <h6>Wallets</h6>
                        <WrappedTokenRiskAccordion 
                            accordionId='whale-count-accordion'
                            title='Number of Whales:'
                            passCondition={!currentRisk.risks.risks.whaleRisk}
                            riskStatusId='whale-numbers'
                            baseContent={'Overall, a higher number of whales with limited individual ownership indicates better distribution and reduces the influence any one whale can exert over the broader market. As with all metrics, analyzing whale counts requires considering the context of the specific asset and its economics.'}
                        >
                            {(currentRisk.risks.risks.whaleRisk) ? 
                                <span>Few whales</span> :
                                <span>Many whales</span>
                            }
                        </WrappedTokenRiskAccordion>
                        <TokenRiskAccordion 
                            accordionId='whale-holdings-accordion'
                            title='Distribution of whale holdings:'
                            passCondition={!currentRisk.risks.risks.whaleRisk}
                            riskStatusId='whale-holdings'
                            baseContent={'Evaluating whale concentration relative to distribution across the broader community provides insights into risks, tokenomics, and market psychology around an asset. More even distribution generally signals a healthier asset.'}
                        >
                            {(currentRisk.risks.risks.whaleRisk) ? 
                                <span>Whale holdings are heavily concentrated</span> :
                                <span>Many smaller whales</span>
                            }
                        </TokenRiskAccordion>
                    </InnerDataBox>
                    <StaticAnalysis staticAnalysis={currentRisk.staticAnalysis}></StaticAnalysis>
                </DataBoxWrapper>
                <DataBoxWrapper title='Contract / AML' className='contract'>
                    {renderLaunchDate(currentRisk, time)}
                    <InnerDataBox xs={6} md={5} wrapperClass='contract'>
                        <h6>Contract details</h6>
                        <WrappedTokenRiskAccordion 
                            accordionId='abi-accordion'
                            title='Abi Verified:'
                            passCondition={!currentRisk.risks.risks.abiRisk}
                            riskStatusId='abi-risk-status'
                            baseContent='A registered ABI allows seamless interaction with a smart contract from various applications in a standardized way. It acts as an interface specification for the contract that is vital for proper encoding of calls/data.'
                        >
                            {(!currentRisk.risks.risks.abiRisk) ? 
                                <span><a href={`https://etherscan.io/token/${currentRisk.contract}#writeContract`}>The Abi</a> has been verified</span> :
                                <span>The Abi has not been verified. This could be indicative of malicious or non transparent behavior on behalf of the deployer. Operate with caution.</span>
                            }
                        </WrappedTokenRiskAccordion>
                    </InnerDataBox>
                    <InnerDataBox xs={6} md={5} wrapperClass='aml'>
                        <h6>AML</h6>
                        <WrappedTokenRiskAccordion 
                            accordionId='contract-sanctioned'
                            title='Contract has been Sanctioned:'
                            passCondition={!currentRisk.contractSanctioned}
                            riskStatusId='contract-sanctioned-status'
                            baseContent={'This contract has been sanctioned by a regulatory entity and carries extreme legal, regulatory, and cybercrime risk'}
                        >
                            {(currentRisk.contractSanctioned === false) ? 
                                <span><a href={`https://etherscan.io/address/${currentRisk.contract}`}>The Contract</a> has not bean sanctioned</span> :
                                <span><a href={`https://etherscan.io/address/${currentRisk.contract}`}>The Contract</a> has been sanctioned</span>
                            }
                        </WrappedTokenRiskAccordion>
                        <TokenRiskAccordion 
                            accordionId='proxy-accordion'
                            title='Contract is an Upgradeable Proxy:'
                            passCondition={!currentRisk.risks.risks.proxyRisk}
                            riskStatusId='proxy-risk-status'
                            baseContent={'The owner of the proxy contract has the ability to upgrade the logic contract at any time. This places a lot of trust in the owner\'s hands.'}
                        >
                            {(currentRisk.risks.risks.proxyRisk) ? 
                                <span><a href={`https://etherscan.io/token/${currentRisk.contract}#writeContract`}>The Contract</a> is an upgradeable proxy</span> :
                                <span><a href={`https://etherscan.io/token/${currentRisk.contract}#writeContract`}>The Contract</a> is immutable</span>
                            }
                        </TokenRiskAccordion>
                    </InnerDataBox>
                    <StaticAnalysis staticAnalysis={currentRisk.staticAnalysis}></StaticAnalysis>
                </DataBoxWrapper>
            </>)
        }
    }

    return (
        <Grid container mt={10} className='tokenLookup'>
            <Grid item xs={12}>
                <TokenSearchBar baseSearch={props.searchString} triggerSearch={props.tokenSearchFunc}/>
                {renderRisks()}
            </Grid>
        </Grid>
    );
}

export default TokenSearchComponent;
