import { PropsWithChildren, JSX, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

export type TokenRiskAccordionProps = PropsWithChildren<Readonly<{
   title: string,
   accordionId: string,
   riskStatusId: string,
   passCondition: boolean,
   baseContent: null | string
}>>

const TokenRiskAccordion = (props: TokenRiskAccordionProps): JSX.Element => {
    const [expanded, setExpanded] = useState(false)
    return(
        <Accordion 
            className="accordionDetail"
            id={props.accordionId}
            aria-controls={props.accordionId}
            disableGutters={true}
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
            data-testid={props.accordionId}
        >
            <AccordionSummary className="accordionTitle">
                <span className='accordionHeaderContents'>{props.title} <span className={props.passCondition ? 'pass' : 'fail'} id={props.riskStatusId}></span></span>
            </AccordionSummary>
            <AccordionDetails className="accordionDescription">
                {props.children}
                <br></br>
                {(props.baseContent === null) ? null : <span>{props.baseContent}</span>}
            </AccordionDetails>
        </Accordion>
    )
};

export {TokenRiskAccordion};