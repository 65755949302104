
import { PropsWithChildren, JSX } from "react";
import { TokenRiskAccordion } from "../TokenRiskAccordion/TokenRiskAccordion";
import { Divider } from "@mui/material";

export type WrappedTokenRiskAccordionProps = PropsWithChildren<Readonly<{
   title: string,
   accordionId: string,
   riskStatusId: string,
   passCondition: boolean,
   baseContent: null | string
}>>

const WrappedTokenRiskAccordion = (props: WrappedTokenRiskAccordionProps): JSX.Element => {
    return(<>
        <TokenRiskAccordion
            title={props.title}
            accordionId={props.accordionId}
            riskStatusId={props.riskStatusId}
            passCondition={props.passCondition}
            baseContent={props.baseContent}
        >
            {props.children}
        </TokenRiskAccordion>

        <Divider className="listLine"/>
    </>)
};

export {WrappedTokenRiskAccordion};