import { Routes, Route } from "react-router-dom"
import TokenScreen from "./screens/TokenScreen/TokenScreen"
import WalletScreen from "./screens/WalletScreen/WalletScreen"
import AssessmentScreen from "./screens/AssessmentScreen/AssessmentScreen"
import SendScreen from './screens/SendScreen/SendScreen'
import HalScreen from './screens/HalScreen/HalScreen'
import Screen404 from './components/Screen404/Screen404'

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={ <TokenScreen/> } />
                <Route path="/token/:searchString" element={ <TokenScreen/> } />
                <Route path='/riskAudit/:assessmentId' element={ <AssessmentScreen />} />
                <Route path="/wallet/:address" element={ <WalletScreen />} />
                <Route path='/send' element={ <SendScreen /> } />
                <Route path='hal' element = { <HalScreen />} />
                <Route path="*" element={ <Screen404 /> }/>
            </Routes>
        </div>
    )
}
export default App