import { PropsWithoutRef, ReactElement, ReactNode, useState } from "react";
import AsyncSelect from "react-select/async";
import { fetchTokens } from "../../ethereumFunctions";
import _ from "lodash";
import { ActionMeta, GroupBase, OptionsOrGroups, SingleValue } from "react-select";
import {InnerDataBox} from '../../components/InnerDataBox/InnerDataBox'
import { DataBoxWrapper } from '../../components/DataBoxWrapper/DataBoxWrapper';

export type TokenSearchBarProps = PropsWithoutRef<Readonly<{
    baseSearch: undefined | string,
    triggerSearch: (s: string) => void
}>>

type EnrichedTokenItem = { value: string; label: string; logo: null | string; possible_spam: boolean}
type EnrichedTokenGroup = OptionsOrGroups<EnrichedTokenItem, GroupBase<EnrichedTokenItem>>



const TokenSearchBar = (props: TokenSearchBarProps): ReactElement<AsyncSelect> => {
    const [searchValue, setSearchValue] = useState<string>(props.baseSearch || '')
    const [tokenLogo, setTokenLogo] = useState<null | string>(null)
    const [tokenLabel, setTokenLabel] = useState('')
    const [tokenPossibleSpam, setTokenPossibleSpam] = useState(false)

    const defaultSearchResults = [{label: 'No current Search', value: '', logo: null, possible_spam: false}]


    const handleChange = (val: SingleValue<EnrichedTokenItem>, _action: ActionMeta<EnrichedTokenItem>): void => {
        if((val !== null) && (val.value !== '')) {
            setSearchValue(val.value)
            setTokenLogo(val.logo)
            setTokenLabel(val.label)
            setTokenPossibleSpam(val.possible_spam)
            props.triggerSearch(val.value)
        }
    }

    const searchForValue = async (val: string, callback: (values: EnrichedTokenGroup) => void): Promise<EnrichedTokenGroup> => {
        const tokens = await fetchTokens(val)
        
        const suspiciousTokens = tokens.filter((t: Readonly<{possible_spam: boolean, label: string, value: string, logo: string | null, decimals: null | undefined | number, marketCap: number, hasAbi: boolean}>) => {
            return (t.possible_spam === true) || (t.decimals === null) || (t.decimals === undefined) || (t.marketCap/t.decimals < 1000000) || (t.hasAbi === false)
        }).map(t => {
            return {
                ...t,
                possible_spam: true
            }
        })
        const likelyTokens = tokens.filter((t: Readonly<{possible_spam: boolean, label: string, value: string, logo: string | null, decimals: null | undefined | number, marketCap: number, hasAbi: boolean}>) => {
            return (t.possible_spam === false) && (t.decimals !== null) && (t.decimals !== undefined) && (t.marketCap/t.decimals > 1000000) && (t.hasAbi === true)
        }).sort((t1, t2) => {
            if ((t1.logo === null) && (t2.logo === null)) {
                return 0
            } else if (t1.logo === null) {
                return 1
            } else if (t2.logo === null) {
                return -1
            } else {
                return 0
            }
        })
        const groupedTokens = [
            {label: 'Likely', options: likelyTokens},
            {label: 'Risky', options: suspiciousTokens}
        ]
        callback(groupedTokens)
        return groupedTokens
    }
    
    const debouncedLoadOptions = _.debounce(searchForValue, 1000)

    const renderLogo = (logo: null | string): ReactNode => {
        if(logo === null){
            return null
        } else {
            return(<img style={{height: "16px", marginRight: "10px", marginLeft: "10px"}} src={logo} />)
        }
    }

    const formatOptionLabel = ({ value, label, logo, possible_spam }: Readonly<EnrichedTokenItem>): ReactElement => {
        return (
            <div style={{ display: "flex" }} className={(possible_spam === true) ? 'unverified' : 'verified'}>
                {renderLogo(logo)}
                <div className="tokenLabel">{label}</div>
                <div className="tokenContract">{value}</div>
                <div className="tokenMainNet">Ethereum Mainnet</div>
            </div>
        )};

    return(<DataBoxWrapper title='Search'>
        <InnerDataBox xs={12} md={12} wrapperClass='add'>
            {/* @ts-expect-error Weird type failure on AsyncSelect, should get fixed in a later version. Probably won't*/}
            <AsyncSelect 
                className='token-select' 
                value={{value: searchValue, label: tokenLabel, logo: tokenLogo, possible_spam: tokenPossibleSpam}} 
                formatOptionLabel={formatOptionLabel} 
                onChange={handleChange} 
                placeholder='Search Ethereum tokens' 
                defaultOptions={defaultSearchResults} 
                loadOptions={debouncedLoadOptions}>      
            </AsyncSelect>
        </InnerDataBox>
    </DataBoxWrapper>)
};

export {TokenSearchBar};