import '../../App.scss';
import React, {useState, JSX} from 'react'
import fl from '../../assets/fidesium-logo-white.png'
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { BarChart } from '@mui/x-charts/BarChart';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CustomTabPanel from '../../components/CustomTabPanel/CustomTabPanel';
import moment from 'moment'

const selectRiskClass = (score: number) => {
    if (score <= 10) {
        return 'ten'
    } else if (score <= 20) {
        return 'twenty'
    } else if (score <= 30) {
        return 'thirty'
    } else if (score <= 40) {
        return 'forty'
    } else if (score <= 50) {
        return 'fifty'
    } else if (score <= 60) {
        return 'sixty'
    } else if (score <= 70) {
        return 'seventy'
    } else if (score <= 80) {
        return 'eighty'
    } else {
        return 'ninety'
    }
}

const mockData = [
    {
        "transactions": {
            "deployedCount": 5,
            "totalCount": 14331,
            "deploymentTransactions": [
                {
                    "address": "0x1901482Fd49A873049f422b35698f6Bd1ceD0E1b",
                    "creator": "0x03D675e4CaF53142C66551dB4986c047BdEb26F8",
                    "hasEns": true,
                    "hasAbi": true,
                    "isFreshie": true,
                    "hasReentrancy": false,
                    "hasSuicidal": false,
                    "modifyingStorage": false,
                    "arbitrarySend": false,
                    "protectedVars": false,
                    "otherFlaws": false,
                    "staticAnalysisFailure": false,
                    "isHoneyPot": false,
                    "erc20": true,
                    "erc721": false,
                    "erc1155": false,
                    "dex": false,
                    "lending": false,
                    "other": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x4B1C7a9938b52B6E3E5A6Cc2c9D1628abECCb96c",
                    "creator": "0xDCd6e0dcE296CC54f8F4C77Ee4C056c72f6f40a4",
                    "hasEns": true,
                    "isFreshie": true,
                    "hasAbi": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x0338169A0e3e91AAbEde30dc8750Abb67f59DF78",
                    "creator": "0xf1F2930B5266e2D9C91a9bBf23AF790aa6F6e5B0",
                    "hasEns": true,
                    "isFreshie": true,
                    "hasAbi": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x240a6971cEc49d64948BC101e5f5C3940D53b833",
                    "creator": "0xA0972729134c4Ac091E3F9C03CbA157A14AA88da",
                    "hasEns": true,
                    "isFreshie": true,
                    "hasAbi": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x0CBaf1EBafDd6631335DEE8b7EC8b6CA58D53a5f",
                    "creator": "0xd2C6aa8632119fDeD3771F389a52243920aB4389",
                    "hasEns": true,
                    "hasAbi": false,
                    "isFreshie": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                }
            ],
            "tooManyDeploysRisk": false,
            "staticAnalysisRisk": false,
            "liquidityRisk": false,
            "amlRisk": false,
            "mixerRisk": false,
            "honeyPotRisk": true,
            "abiRisk": false,
            "proxyRisk": true,
            "risk": 25
        }
    },
    {
        "transactions": {
            "deployedCount": 11,
            "totalCount": 14992,
            "deploymentTransactions": [
                {
                    "address": "0x0CBaf1EBafDd6631335DEE8b7EC8b6CA58D53a5f",
                    "creator": "0xd2C6aa8632119fDeD3771F389a52243920aB4389",
                    "hasEns": true,
                    "isFreshie": false,
                    "hasAbi": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x2883CF1F76ed29aECEE9298A9c5E201037cA419a",
                    "creator": "0xb1F21bcDDB961760B1080d265Dd1F307396936a1",
                    "hasEns": true,
                    "hasAbi": false,
                    "isFreshie": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x8943EA5Bb03A4bE34bf23807A6a578FF2211F4e2",
                    "creator": "0x90c378f4D38E68F476d3de6fD43EC5728Bf789d8",
                    "hasEns": false,
                    "hasAbi": false,
                    "isFreshie": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x65ED9463CAaBb4DFe76A94AbA7488cAf65Fb9475",
                    "creator": "0x25c74A9cF221548327EBd3734CE0fDddb12AE1F3",
                    "hasEns": true,
                    "hasAbi": false,
                    "isFreshie": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x6002D08d7a489D28004B4e6ce8e0d6135D715C66",
                    "creator": "0x688D5fc8C5Cf798B41eBcB9955b51E81B79bc3e9",
                    "hasEns": true,
                    "hasAbi": true,
                    "hasReentrancy": false,
                    "hasSuicidal": false,
                    "modifyingStorage": false,
                    "arbitrarySend": false,
                    "protectedVars": false,
                    "otherFlaws": false,
                    "staticAnalysisFailure": false,
                    "isHoneyPot": false,
                    "erc20": true,
                    "erc721": false,
                    "erc1155": false,
                    "dex": false,
                    "lending": false,
                    "other": false,
                    "isFreshie": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x2DE1DbFad03f3e6A790220f69D3942e57886244D",
                    "creator": "0x595173Ec6437CBdE23b97234A80d489c6E72185a",
                    "hasEns": true,
                    "hasAbi": true,
                    "hasReentrancy": false,
                    "hasSuicidal": false,
                    "modifyingStorage": false,
                    "arbitrarySend": false,
                    "protectedVars": false,
                    "otherFlaws": false,
                    "staticAnalysisFailure": false,
                    "proxy": false,
                    "erc20": false,
                    "erc721": false,
                    "erc1155": false,
                    "dex": false,
                    "lending": false,
                    "other": false,
                    "isFreshie": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x45056743FFF5Bd4C6CF045e732A31d55239ffaF0",
                    "creator": "0x59De70Ddc50281635C363e8DA0727fDC38042CA0",
                    "hasEns": true,
                    "hasAbi": false,
                    "isFreshie": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0xf96d1961c3402B62AE5b1Db921b8BC010788a8E8",
                    "creator": "0xed3504B5Bc8A761D1875600ef8D74DD2cac82c5F",
                    "hasEns": true,
                    "hasAbi": true,
                    "hasReentrancy": false,
                    "hasSuicidal": false,
                    "modifyingStorage": false,
                    "arbitrarySend": false,
                    "protectedVars": false,
                    "otherFlaws": false,
                    "staticAnalysisFailure": false,
                    "isHoneyPot": false,
                    "erc20": true,
                    "erc721": false,
                    "erc1155": false,
                    "dex": false,
                    "lending": false,
                    "other": false,
                    "isFreshie": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0xE56d406f897e9AAC4dEED94eFed60095bfe59D67",
                    "creator": "0xb1F21bcDDB961760B1080d265Dd1F307396936a1",
                    "hasEns": true,
                    "hasAbi": false,
                    "isFreshie": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x1Be822BE838A3055bA9a0d0daC1613d8f6cdEB93",
                    "creator": "0xF1f106e4d834f25895C1058039453280F9009F11",
                    "hasEns": true,
                    "hasAbi": false,
                    "isFreshie": true,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0xC008098d5709ca416aD7dFAd89Aa8c4E84Ea4532",
                    "creator": "0x86110318cFd8a20c0Bd571321589A0aDCc7381e6",
                    "hasEns": true,
                    "hasAbi": true,
                    "hasReentrancy": false,
                    "hasSuicidal": false,
                    "modifyingStorage": false,
                    "arbitrarySend": false,
                    "protectedVars": false,
                    "otherFlaws": false,
                    "staticAnalysisFailure": false,
                    "isHoneyPot": false,
                    "erc20": true,
                    "erc721": false,
                    "erc1155": false,
                    "dex": false,
                    "lending": false,
                    "other": false,
                    "isFreshie": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                }
            ],
            "tooManyDeploysRisk": false,
            "staticAnalysisRisk": false,
            "liquidityRisk": false,
            "amlRisk": false,
            "mixerRisk": false,
            "honeyPotRisk": false,
            "abiRisk": false,
            "proxyRisk": false,
            "risk": 16
        }
    },
    {
        "transactions": {
            "deployedCount": 0,
            "totalCount": 5764,
            "deploymentTransactions": [
            ],
            "tooManyDeploysRisk": false,
            "staticAnalysisRisk": false,
            "liquidityRisk": false,
            "amlRisk": false,
            "mixerRisk": false,
            "honeyPotRisk": true,
            "abiRisk": false,
            "proxyRisk": false,
            "risk": 11
        }
    },
    {
        "transactions": {
            "deployedCount": 7,
            "totalCount": 9412,
            "deploymentTransactions": [
                {
                    "address": "0x1901482Fd49A873049f422b35698f6Bd1ceD0E1b",
                    "creator": "0x03D675e4CaF53142C66551dB4986c047BdEb26F8",
                    "hasEns": true,
                    "isFreshie": true,
                    "hasAbi": true,
                    "hasReentrancy": false,
                    "hasSuicidal": false,
                    "modifyingStorage": false,
                    "arbitrarySend": false,
                    "protectedVars": false,
                    "otherFlaws": false,
                    "staticAnalysisFailure": false,
                    "isHoneyPot": false,
                    "erc20": true,
                    "erc721": false,
                    "erc1155": false,
                    "dex": false,
                    "lending": false,
                    "other": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x10Fdb5028103cB520203946d1D1eFDf6b2694081",
                    "creator": "0x5F11afb63DfDBd18Cd956AD50732C220160004C1",
                    "hasEns": true,
                    "isFreshie": true,
                    "hasAbi": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x9bdF6BEc0F64aB36c30D225a3Bc96F50d061832b",
                    "creator": "0x4F133dAF00c02b38cAfbdb9000890e57a24b82C3",
                    "hasEns": true,
                    "isFreshie": true,
                    "hasAbi": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x4B1C7a9938b52B6E3E5A6Cc2c9D1628abECCb96c",
                    "creator": "0xDCd6e0dcE296CC54f8F4C77Ee4C056c72f6f40a4",
                    "hasEns": true,
                    "isFreshie": false,
                    "hasAbi": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x0338169A0e3e91AAbEde30dc8750Abb67f59DF78",
                    "creator": "0xf1F2930B5266e2D9C91a9bBf23AF790aa6F6e5B0",
                    "hasEns": true,
                    "isFreshie": true,
                    "hasAbi": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x240a6971cEc49d64948BC101e5f5C3940D53b833",
                    "creator": "0xA0972729134c4Ac091E3F9C03CbA157A14AA88da",
                    "hasEns": true,
                    "isFreshie": false,
                    "hasAbi": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x0CBaf1EBafDd6631335DEE8b7EC8b6CA58D53a5f",
                    "creator": "0xd2C6aa8632119fDeD3771F389a52243920aB4389",
                    "hasEns": true,
                    "isFreshie": true,
                    "hasAbi": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                }
            ],
            "tooManyDeploysRisk": true,
            "staticAnalysisRisk": false,
            "liquidityRisk": false,
            "amlRisk": false,
            "mixerRisk": false,
            "honeyPotRisk": true,
            "abiRisk": false,
            "proxyRisk": true,
            "risk": 22
        }
    },
    {
        "transactions": {
            "deployedCount": 2,
            "totalCount": 35087,
            "deploymentTransactions": [
                {
                    "address": "0xd1427799cD1CFccfB9C4Cb7828634d47db79cC43",
                    "creator": "0xE5D057a6eE87668C6e446612435d504a2b93244B",
                    "hasEns": false,
                    "hasAbi": true,
                    "hasReentrancy": true,
                    "hasSuicidal": false,
                    "modifyingStorage": false,
                    "arbitrarySend": false,
                    "protectedVars": false,
                    "otherFlaws": false,
                    "staticAnalysisFailure": false,
                    "isHoneyPot": true,
                    "erc20": true,
                    "erc721": false,
                    "erc1155": false,
                    "dex": false,
                    "lending": false,
                    "other": false,
                    "isFreshie": true,
                    "touchedMixer": true,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x9eeBB562eC5Cb74F1ea5d0C03F07584A30bb2d5C",
                    "creator": "0xbc419e16DEe4992740FC504b9Dc522A499d6E852",
                    "hasEns": true,
                    "hasAbi": true,
                    "hasReentrancy": false,
                    "hasSuicidal": false,
                    "modifyingStorage": false,
                    "arbitrarySend": false,
                    "protectedVars": false,
                    "otherFlaws": false,
                    "staticAnalysisFailure": false,
                    "proxy": true,
                    "erc20": false,
                    "erc721": false,
                    "erc1155": false,
                    "dex": false,
                    "lending": false,
                    "other": false,
                    "isFreshie": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": true,
                    "knownScammer": false,
                    "knownScammerInteract": true
                }
            ],
            "tooManyDeploysRisk": false,
            "staticAnalysisRisk": false,
            "liquidityRisk": false,
            "amlRisk": true,
            "mixerRisk": true,
            "honeyPotRisk": true,
            "abiRisk": true,
            "proxyRisk": true,
            "risk": 62
        }
    },
    {
        "transactions": {
            "deployedCount": 4,
            "totalCount": 13234,
            "deploymentTransactions": [
                {
                    "address": "0xFD3042fa646443CaE702d6d9C5bfc30A25F4D3Ec",
                    "creator": "0x436e7D5ccbef0D1D9A7835dfD05799A8c3FCE74C",
                    "hasEns": true,
                    "hasAbi": false,
                    "isFreshie": true,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0xd1427799cD1CFccfB9C4Cb7828634d47db79cC43",
                    "creator": "0xE5D057a6eE87668C6e446612435d504a2b93244B",
                    "hasEns": true,
                    "hasAbi": true,
                    "hasReentrancy": true,
                    "hasSuicidal": false,
                    "modifyingStorage": false,
                    "arbitrarySend": false,
                    "protectedVars": false,
                    "otherFlaws": false,
                    "staticAnalysisFailure": false,
                    "isHoneyPot": false,
                    "erc20": true,
                    "erc721": false,
                    "erc1155": false,
                    "dex": false,
                    "lending": false,
                    "other": false,
                    "isFreshie": true,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x9eeBB562eC5Cb74F1ea5d0C03F07584A30bb2d5C",
                    "creator": "0xbc419e16DEe4992740FC504b9Dc522A499d6E852",
                    "hasEns": true,
                    "hasAbi": true,
                    "hasReentrancy": false,
                    "hasSuicidal": false,
                    "modifyingStorage": false,
                    "arbitrarySend": false,
                    "protectedVars": false,
                    "otherFlaws": false,
                    "staticAnalysisFailure": false,
                    "proxy": true,
                    "erc20": false,
                    "erc721": false,
                    "erc1155": false,
                    "dex": false,
                    "lending": false,
                    "other": false,
                    "isFreshie": true,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0xfBB54BdBCA330B58A480392a6e033b479Ac3BE79",
                    "creator": "0x275b3E5AA47F1d0abE7c6c265d11B6cEF66b95d8",
                    "hasEns": true,
                    "hasAbi": true,
                    "hasReentrancy": true,
                    "hasSuicidal": false,
                    "modifyingStorage": false,
                    "arbitrarySend": false,
                    "protectedVars": false,
                    "otherFlaws": true,
                    "staticAnalysisFailure": false,
                    "isHoneyPot": false,
                    "erc20": true,
                    "erc721": false,
                    "erc1155": false,
                    "dex": false,
                    "lending": false,
                    "other": false,
                    "isFreshie": true,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                }
            ],
            "tooManyDeploysRisk": false,
            "staticAnalysisRisk": false,
            "liquidityRisk": false,
            "amlRisk": false,
            "mixerRisk": false,
            "honeyPotRisk": false,
            "abiRisk": false,
            "proxyRisk": false,
            "risk": 19
        }
    },
    {
        "transactions": {
            "deployedCount": 1,
            "totalCount": 10013,
            "deploymentTransactions": [
                {
                    "address": "0xd1427799cD1CFccfB9C4Cb7828634d47db79cC43",
                    "creator": "0xE5D057a6eE87668C6e446612435d504a2b93244B",
                    "hasEns": true,
                    "hasAbi": false,
                    "isFreshie": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                }
            ],
            "tooManyDeploysRisk": false,
            "staticAnalysisRisk": false,
            "liquidityRisk": false,
            "amlRisk": false,
            "mixerRisk": false,
            "honeyPotRisk": false,
            "abiRisk": false,
            "proxyRisk": false,
            "risk": 17
        }
    },
    {
        "transactions": {
            "deployedCount": 6,
            "totalCount": 11720,
            "deploymentTransactions": [
                {
                    "address": "0x1901482Fd49A873049f422b35698f6Bd1ceD0E1b",
                    "creator": "0x03D675e4CaF53142C66551dB4986c047BdEb26F8",
                    "hasEns": true,
                    "hasAbi": true,
                    "isFreshie": true,
                    "hasReentrancy": false,
                    "hasSuicidal": false,
                    "modifyingStorage": false,
                    "arbitrarySend": false,
                    "protectedVars": false,
                    "otherFlaws": false,
                    "staticAnalysisFailure": false,
                    "isHoneyPot": true,
                    "erc20": true,
                    "erc721": false,
                    "erc1155": false,
                    "dex": false,
                    "lending": false,
                    "other": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x0338169A0e3e91AAbEde30dc8750Abb67f59DF78",
                    "creator": "0xf1F2930B5266e2D9C91a9bBf23AF790aa6F6e5B0",
                    "hasEns": true,
                    "isFreshie": false,
                    "hasAbi": false,
                    "touchedMixer": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x240a6971cEc49d64948BC101e5f5C3940D53b833",
                    "creator": "0xA0972729134c4Ac091E3F9C03CbA157A14AA88da",
                    "hasEns": true,
                    "isFreshie": false,
                    "touchedMixer": false,
                    "hasAbi": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x0CBaf1EBafDd6631335DEE8b7EC8b6CA58D53a5f",
                    "creator": "0xd2C6aa8632119fDeD3771F389a52243920aB4389",
                    "hasEns": false,
                    "touchedMixer": false,
                    "isFreshie": true,
                    "hasAbi": false,
                    "sanctionedAmlInteractions": false,
                    "knownScammer": false,
                    "knownScammerInteract": false
                },
                {
                    "address": "0x201B6aD332F7Fa2D44CC9C6AfB3AB11678751aF7",
                    "creator": "0xbc419e16DEe4992740FC504b9Dc522A499d6E852",
                    "hasEns": true,
                    "hasAbi": true,
                    "hasReentrancy": true,
                    "hasSuicidal": false,
                    "modifyingStorage": false,
                    "arbitrarySend": false,
                    "protectedVars": false,
                    "otherFlaws": true,
                    "staticAnalysisFailure": false
                },
                {
                    "address": "0x9eeBB562eC5Cb74F1ea5d0C03F07584A30bb2d5C",
                    "creator": "0xbc419e16DEe4992740FC504b9Dc522A499d6E852",
                    "hasEns": true,
                    "hasAbi": true,
                    "hasReentrancy": false,
                    "hasSuicidal": false,
                    "modifyingStorage": false,
                    "arbitrarySend": false,
                    "protectedVars": false,
                    "otherFlaws": false,
                    "staticAnalysisFailure": false,
                    "proxy": true,
                    "erc20": false,
                    "erc721": false,
                    "erc1155": false,
                    "dex": false,
                    "lending": false,
                    "other": false
                }
            ],
            "tooManyDeploysRisk": false,
            "staticAnalysisRisk": true,
            "liquidityRisk": false,
            "amlRisk": false,
            "mixerRisk": false,
            "honeyPotRisk": true,
            "abiRisk": false,
            "proxyRisk": false,
            "risk": 37
        }
    }
]



const purchaseCounts = mockData.map(d => d.transactions.deployedCount)
const latestRisk = mockData[7].transactions.risk
const fifteenMinAgo = ((mockData[7].transactions.risk - mockData[6].transactions.risk)/mockData[6].transactions.risk)*100
const thirtyMinAgo = ((mockData[7].transactions.risk - mockData[5].transactions.risk)/mockData[5].transactions.risk)*100
const sixtyMinAgo = ((mockData[7].transactions.risk - mockData[4].transactions.risk)/mockData[4].transactions.risk)*100
const currentTime = moment()
const fiteenAgoTime = currentTime.clone().subtract(15, 'minutes')
const sixtyAgoTime = currentTime.clone().subtract(60, 'minutes')
const thirtyAgoTime = currentTime.clone().subtract(30, 'minutes')
const seventyFigeAgoTime = currentTime.clone().subtract(75, 'minutes')
const ninetyAgoTime = currentTime.clone().subtract(90, 'minutes')
const onetwentyAgoTime = currentTime.clone().subtract(120, 'minutes')
const oneFiftyAgoTime = currentTime.clone().subtract(150, 'minutes')
const minutesRange = [oneFiftyAgoTime.format('hh:mm'), onetwentyAgoTime.format('hh:mm'), ninetyAgoTime.format('hh:mm'), seventyFigeAgoTime.format('hh:mm'), sixtyAgoTime.format('hh:mm'), thirtyAgoTime.format('hh:mm'), fiteenAgoTime.format('hh:mm'), currentTime.format('hh:mm')]

const selectColorForChange = (change: number): string => (change < 0) ? 'hal-plus' : 'hal-minus'
const prefixWithPlus = (change: number): string => (change < 0) ? `${change.toPrecision(4)}%` : `+${change.toPrecision(4)}%`
const ChartsOverviewDemo = () => {
    return (
        <BarChart
            series={[
                { data: purchaseCounts },
            ]}
            height={290}
            xAxis={[{ data: minutesRange, scaleType: 'band' }]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
        />
    );
}

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const HalScreen = (): JSX.Element => {
    const [tabValue, setTabValue] = useState(0)
    const [blockIndex, setBlockIndex] = useState(7)
    const [subRiskStyle, setSubRiskStyle] = useState('thirty-bg')

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const selectContractType = (dTx: any) => {
        if (!dTx.hasAbi) {
            return 'unknown'
        } else if (dTx.erc20) {
            return 'erc20'
        } else if (dTx.erc1155) {
            return 'erc1155'
        } else if (dTx.erc721) {
            return 'erc721'
        } else if(dTx.dex) {
            return 'a swap'
        } else if (dTx.lending) {
            return 'a lending protocol'
        } else if (dTx.other) {
            return 'a defi product'
        }
    }

    const selectBlockSummary = (index: number) => {
        setBlockIndex(index)
        setTabValue(0)
        const riskStyle = selectRiskClass(mockData[index].transactions.risk)
        setSubRiskStyle(`${riskStyle}-bg`)
    }

    const renderButtons = (data: Array<any>): JSX.Element | null => {
        if (data.length === 0) {
            return null
        } else {
            const buttons = data.map((d, index) => {
                const riskStyle = selectRiskClass(d.transactions.risk)
                return (<Button className={`hal-block-button ${riskStyle}-bg`} onClick={() => selectBlockSummary(index)} key={`renderButton-${index}`}>
                    {d.transactions.risk}
                </Button>)
            })
            return <>
                {buttons}
            </>
        }
    }

    const generateTabs = (dTxs: Array<any>): JSX.Element | null => {
        if (dTxs.length === 0) {
            return (<Grid sm={12} md={8}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs>
                    </Tabs>
                </Box>
                <div className='hal-content'><CustomTabPanel value={tabValue} index={0}>
                    <p>No contracts deployed in Block range</p></CustomTabPanel>
                </div>
            </Grid>)
        } else {
            const tabHeadings = dTxs.map((dTx, index) => {
                return (<Tab className='hal-tab' label={dTx.address.slice(0,5) + '...' + dTx.address.slice(-5)} {...a11yProps(index)} key={`contractTab-${index}`}/>)
            })

            const additionalDetails = (dTx: any) => {
                if (!dTx.hasAbi) {
                    return null
                } else if (!dTx.hasReentrancy && !dTx.hasSuicidal && !dTx.modifyingStorage && !dTx.arbitraySend && !dTx.protectedVars && !dTx.otherFlaws){
                    return (<>
                        <span>Contract has no serious code flaws</span><br></br>
                    </>)
                } else {
                    return (<>
                        <p>Contract has {dTx.hasReentrancy ? '' : 'no'} reentrancy vulnerabilities</p>
                        <p>Contract has {dTx.hasSuicidal ? '' : 'no'} suicidal calls</p>
                        <p>Contract does {dTx.modifyingStorage ? '' : 'not'} modify storage</p>
                        <p>Contract does {dTx.arbitrarySend ? '' : 'not'} send to arbitrary addresses</p>
                        <p>Contract does {dTx.protectedVars ? '' : 'not'} modify protected variables</p>
                        <p>Contract {dTx.otherFlaws ? 'has' : 'does not have'} significant further flaws</p>
                    </>)
                }
            }
            const tabs = dTxs.map((dTx, index) => {
                return (<div className='hal-content' key={`tabBody-${index}`}><CustomTabPanel value={tabValue} index={index}>
                    <h2 className="hal-contract">Contract address: <a href={`https://etherscan.io/address/${dTx.address}`}>{dTx.address}</a></h2>
                    <p>Contract Type is {selectContractType(dTx)}</p>
                    <p>ABI has {dTx.hasAbi ? '' : 'not'} been verified</p>
                    <p>Deployment Wallet has {dTx.touchedMixer ? '' : 'not'} used a mixer</p>
                    <p>Deployment Wallet is {dTx.isFreshie ? '' : 'not'} freshly funded</p>
                    <p>Deployment Wallet is {dTx.knownScammer ? '' : 'not'} a known scammer</p>
                    <p>Deployment Wallet has {dTx.knownScammerInteract ? '' : 'not'} interacted with a known scammer</p>
                    <p>Deployment Wallet has {dTx.sanctionedAmlInteractions ? '' : 'not'} interacted with a sanctioned entity</p>
                    <p>Contract is {dTx.isHoneyPot ? '' : 'not'} a honeypot</p>
                    {additionalDetails(dTx)}
                </CustomTabPanel></div>)
            })
            return(
                <Grid sm={12} md={8}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleChange}>
                            {tabHeadings}
                        </Tabs>
                    </Box>
                    {tabs}
                </Grid>
            )
        }
    }

    return (
        <div className="wrapper">
            <AppBar className="newAppBar" elevation={0}>
                <Toolbar className="newToolBar">
                    <Box className="logo"><img className="logo" src={fl} alt="Fidesium logo"/></Box>
                </Toolbar>
            </AppBar>
            <Grid container spacing={2} my={8} className="hal-report" maxWidth="xl">
                <Grid sm={12} md={4}>
                    <h2>Chain: ETH</h2>
                    <Box className="hal-box chain-stats">
                        <p>Current chain risk:</p>
                        <div className="hal-score">{latestRisk}</div>
                        <table className="hal-change">
                            <tr>
                                <td className="hal-left">15 min</td>
                                <td className="hal-right"><span className={selectColorForChange(fifteenMinAgo)}>{prefixWithPlus(fifteenMinAgo)}</span></td>
                            </tr>
                            <tr>
                                <td className="hal-left">30 min</td>
                                <td className="hal-right"><span className={selectColorForChange(thirtyMinAgo)}>{prefixWithPlus(thirtyMinAgo)}</span></td>
                            </tr>
                            <tr>
                                <td className="hal-left">1 hour</td>
                                <td className="hal-right"><span className={selectColorForChange(sixtyMinAgo)}>{prefixWithPlus(sixtyMinAgo)}</span></td>
                            </tr>
                        </table>
                    </Box>
                </Grid>
                <Grid sm={12} md={8}>
                    {ChartsOverviewDemo()}
                </Grid>
                <Grid sm={12} md={4} className="hal-row">
                    <h2 className="hal-select">Seelect block:</h2>
                </Grid>
                <Grid sm={12} md={8} className="hal-select-buttons">
                    {renderButtons(mockData)}
                </Grid>
                <Grid sm={12} md={4}>
                    <h2>Current block: {currentTime.format('hh:mm')}</h2>
                    <Box className="hal-box">
                        <p>Block risk:<span className={`hal-score-block ${subRiskStyle}`}>{mockData[blockIndex].transactions.risk}</span></p><br/>
                        <p>Total transaction count: <span>{mockData[blockIndex].transactions.totalCount}</span></p>
                        <p>Total deploy transactions: <span>{mockData[blockIndex].transactions.deployedCount}</span></p>
                        <p>Transact to deploy ratio is: <span>{mockData[blockIndex].transactions.tooManyDeploysRisk ? 'unhealthy' : 'healthy'}</span></p>
                        <p>Tumbled funds: <span>{mockData[blockIndex].transactions.mixerRisk || mockData[blockIndex].transactions.amlRisk ? 'high' : 'low'}</span></p>
                        <p>Honeypots are: <span>{mockData[blockIndex].transactions.honeyPotRisk ? 'high' : 'low'}</span></p>
                    </Box>
                </Grid>
                {generateTabs( mockData[blockIndex].transactions.deploymentTransactions)}
            </Grid>
        </div>
    )
}

export default HalScreen;