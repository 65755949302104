import { ReactElement, ReactNode } from 'react'
import AssessmentTabList from '../AssessmentTabList/AssessmentTabList'
import AssessmentTab from '../AssessmentTab/AssessmentTab'
import { Grid } from '@mui/material'

type AssessmentVersionOneProps = Readonly<{
    metadata: any,
    fullMetadataUri: string,
    fullReportUrl: string
}>

const renderAmlWalletChains = (walletChains: Record<string, any>): ReactNode => {
    if (Object.keys(walletChains).length > 0) {
        return Object.keys(walletChains).map(key => {
            return(<>
                <Grid className="field" item xs={4} md={4}>Transaction id:</Grid>
                <Grid className="data" item xs={8} md={8}>{key}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Transaction age: </Grid>
                <Grid className="data" item xs={8} md={8}>{walletChains[key].age}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Transaction amount:</Grid>
                <Grid className="data" item xs={8} md={8}>{walletChains[key].amount} ETH</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Transaction source:</Grid>
                <Grid className="data" item xs={8} md={8}>{walletChains[key].exchangeTag}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Transaction source issue:</Grid>
                <Grid className="data" item xs={8} md={8}>{walletChains[key].exchangeIssue}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Transaction impact:</Grid>
                <Grid className="data" item xs={8} md={8}>{walletChains[key].impact}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Transaction relevance chain:</Grid>
                <Grid className="data" item xs={8} md={8}>{walletChains[key].walletChain}</Grid>
            </>)
        })
    } else {
        return null
    }
}

const renderWhaleRisks = (whaleRisks: any): ReactNode => {
    if (Object.keys(whaleRisks).length > 0) {
        return Object.keys(whaleRisks).map((key, index) => {
            return(<>

                <Grid className="split" item xs={12}>Whale {index +1}</Grid>
                
                <Grid className="field" item xs={4} md={4}>Whale address:</Grid>
                <Grid className="data" item xs={8} md={8}>{key}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Whale is a contract:</Grid>
                <Grid className="data" item xs={8} md={8}>{(!!whaleRisks[key].contract).toString()}</Grid>
                <hr/>

                {whaleRisks[key].contract ? <><Grid className="field" item xs={4} md={4}>Whale was deployed by:</Grid> <Grid item className="data" xs={8} md={8}>{whaleRisks[key].deployer}</Grid><hr/></> : ''}              

                <Grid className="field" item xs={4} md={4}>Major sources of funding:</Grid>
                <Grid className="data" item xs={8} md={8}>
                    {(whaleRisks[key].funding || []).map((funder: any) => {
                        return <Grid className="data" item xs={8} md={8} key={key + '-whale-funder-'+funder}>{funder}</Grid>
                    })}
                </Grid>
                <hr/>

                <Grid className="field" item xs={12} md={12}>
                    {whaleRisks[key].contract ? (whaleRisks[key].proxy ? <div>Whale is a proxy contract</div> : <div>Whale is not a proxy contract</div>)  : ''}
                </Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Whale is holding:</Grid> 
                <Grid className="data" item xs={8} md={8}>{(whaleRisks[key].supply === 0) ? 'Less than 0.5%' : `${whaleRisks[key].supply*100}%`}</Grid>
                <hr/>

                {(whaleRisks[key].sybilled) ? 
                    <><Grid className="field" item xs={4} md={4}>Whale is sybilled with other whales:</Grid><Grid className="data" item xs={8} md={8}>{(whaleRisks[key].sybilled.map((sybil: any) => {
                        return <span key={`${key}-sybilled-whale-${sybil}`}> {sybil}</span>
                    }))
                    }</Grid><hr/></> : ''}

                <Grid className="field" item xs={12} md={12}>
                    {whaleRisks[key].contract ? (whaleRisks[key].unverified ? <div>Whale is an unverified contract</div> : <div>Whale is a verified contract</div>)  : ''}
                </Grid>
            </>)
        })
    } else {
        return null
    }

}

const renderSolcVersionVulnerability = (tRisk: any): ReactElement => {
    return (<>
        <Grid className="field" item xs={4} md={4}>Solc Version Used:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.version}</Grid>
        <hr/>
    </>)
}

const renderMissingImmutableVulnerability = (tRisk: any): ReactElement => {
    return (<>
        <Grid className="field" item xs={4} md={4}>Variable Affected:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.variable}</Grid>
        <hr/>
    </>)
}

const renderReentrancyVulnerability = (tRisk: any): ReactElement => {
    return(<>

        <Grid className="field" item xs={4} md={4}>Function Affected:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.function}</Grid>
        <hr/>

        <Grid className="field" item xs={4} md={4}>External Contract called:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.contractCall}</Grid>
    </>)
}

const renderVulnerabilities = (techRisk: any): ReactNode => {
    if (techRisk.length > 0) {
        return techRisk.map((tRisk: any, index: number) => {
            return(<>

                <Grid className="split" item xs={12}>Vulnerability {index + 1}</Grid>
                
                <Grid className="field" item xs={4} md={4}>Vulnerability Summary:</Grid>
                <Grid className="data" item xs={8} md={8}>{tRisk.type}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Vulnerability impact:</Grid>
                <Grid className="data" item xs={8} md={8}>{tRisk.impact}</Grid>
                <hr/>

                {tRisk.type === 'reentrancy' ? renderReentrancyVulnerability(tRisk): ''}
                {tRisk.type === 'missingImmutable' ? renderMissingImmutableVulnerability(tRisk) : ''}
                {tRisk.type === 'solc_version' ? renderSolcVersionVulnerability(tRisk) : ''}
            </>)
        })
    } else {
        return null
    }
}

const AssessmentVersionOne = ({metadata, fullMetadataUri, fullReportUrl}: AssessmentVersionOneProps): ReactElement => {

    return (
        <AssessmentTabList activeTabIndex={0}>
            <AssessmentTab score={metadata.risk} label={'Report Summary'}>
                <Grid className="field" item xs={4} md={4}>Report on:</Grid>
                <Grid className="data" item xs={8} md={8}>{metadata.client}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Report block:</Grid>
                <Grid className="data" item xs={8} md={8}>19949678</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Report chain:</Grid>
                <Grid className="data" item xs={8} md={8}>Ethereum Mainnet</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Risk summary:</Grid>
                <Grid className="data" item xs={8} md={8}><span className="riskScore-sm twenty-bg"><span>{metadata.risk}</span></span></Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Report number:</Grid>
                <Grid className="data" item xs={8} md={8}>{'This is the initial assessment'}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Detailed Metadata Storage:</Grid>
                <Grid className="data" item xs={8} md={8}><a href={fullMetadataUri} target="_blank" rel="noreferrer">Permalink</a></Grid>
                <hr/>

                <Grid className="field" item xs={12} md={12}>This is a {metadata.prelaunch ? 'prelaunch' : 'post launch'} assessment</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>The root contract analyzed was:</Grid>
                <Grid className="data" item xs={8} md={8}>{metadata.contract}</Grid>
            </AssessmentTab>

            <AssessmentTab score={metadata.risks.aml.risk} label={'Aml Risk'}>
                {renderAmlWalletChains(metadata.risks.aml.walletChains)}
            </AssessmentTab>

            <AssessmentTab score={metadata.risks.team.risk} label={'Team Risk'}>
                <Grid className="field" item xs={4} md={4}>Team doxxing status: </Grid>
                <Grid className="data" item xs={8} md={8}>{metadata.risks.team['doxxed-public'] ? 'public' : (metadata.risks.team['doxxed'] ? 'private': 'anonymous')}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Team experience:</Grid>
                <Grid className="data" item xs={8} md={8}>{metadata.risks.team.experience}</Grid>
            </AssessmentTab>

            <AssessmentTab score={metadata.risks.liquidity.prelaunch ? 'N/A' : metadata.risks.team.risk} label={'Liquidity Risk'}>
                {metadata.risks.liquidity.prelaunch ? 'As this is a prelaunch assessment, liquidity analytics are not included' : ''}
            </AssessmentTab>

            <AssessmentTab score={metadata.risks.whales.risk} label={'Whale Risk'}>
                <Grid item className="field" xs={12} md={12}>{metadata.risks.whales.prelaunch ? 'As this is a prelaunch assessment, whale analytics are of limited value' : ''}</Grid>
                {renderWhaleRisks(metadata.risks.whales.whales)}
            </AssessmentTab>

            <AssessmentTab score={metadata.risks.technicalRisk.risk} label={'Contract Risk'}>
                <Grid className="field" item xs={4} md={4}>Contract is a Honeypot:</Grid>
                <Grid className="data" item xs={8} md={8}>{metadata.risks.technicalRisk.honeyPot.toString()}</Grid>

                <Grid className="field" item xs={4} md={4}>Contract has tax:</Grid>
                <Grid className="data" item xs={8} md={8}>{metadata.risks.technicalRisk.tax.toString()}</Grid>
                {renderVulnerabilities(metadata.risks.technicalRisk.vulnerabilities)}
            </AssessmentTab>

            <AssessmentTab score={metadata.risk} label={'Full Report'}>
                <Grid className="field" item xs={4} md={4}>Detailed Report Link:</Grid>
                <Grid className="data" item xs={8} md={8}><a href={fullReportUrl} target="_blank" rel="noreferrer">Report link</a></Grid>

                <Grid className="field" item xs={12}><iframe src={fullReportUrl} width="100%" height="400px"></iframe></Grid>
            </AssessmentTab>
        </AssessmentTabList>
    )

}

export default AssessmentVersionOne