import { PropsWithChildren, JSX } from "react";
import { WrappedTokenRiskAccordion } from "../WrappedTokenRiskAccordion/WrappedTokenRiskAccordion";

export type StaticAnalysisRiskAccordionProps = PropsWithChildren<Readonly<{
   title: string,
   accordionId: string,
   riskStatusId: string,
   passCondition: boolean,
   baseContent: null | string
}>>

const StaticAnalysisRiskAccordion = (props: StaticAnalysisRiskAccordionProps): JSX.Element => {
    if(props.passCondition === true) {
        return <></>
    } else {
        return <WrappedTokenRiskAccordion
            title={props.title}
            accordionId={props.accordionId}
            riskStatusId={props.riskStatusId}
            passCondition={props.passCondition}
            baseContent={props.baseContent}>
            {props.children}
        </WrappedTokenRiskAccordion>
    }
};

export {StaticAnalysisRiskAccordion};