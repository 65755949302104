// import { DataGrid, GridCell, GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import {useState, JSX} from 'react'
import { Grid, Table, TableHeaderRow, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { Column } from '@devexpress/dx-react-grid';
import {
    SortingState,
    IntegratedSorting,
    IntegratedPaging
} from '@devexpress/dx-react-grid';
import AssetGridTableHeaderCell from '../AssetGridTableHeaderCell/AssetGridTableHeaderCell';
import { AssetGridRow as AssetGridRowType } from '../../types/AssetGrid/AssetGridRow';
import AssetGridRow from '../AssetGridRow/AssetGridRow';
import AssetGridCell from '../AssetGridCell/AssetGridCell';
import { PagingState } from '@devexpress/dx-react-grid';

type Asset = Readonly<{
    balance: string,
    decimals: null | number,
    logo: null | string,
    name: string,
    possible_spam: boolean,
    symbol: string,
    thumbnail: null | string,
    token_address: string
}>
type AssetGridProps = Readonly<{
    wallet: string | undefined,
    assets: ReadonlyArray<Asset>
}>

const constructRow = (asset: Asset): AssetGridRowType => {
    return {
        id: asset.token_address,
        icon: asset.thumbnail || asset.logo,
        symbol: asset.symbol,
        address: asset.token_address,
        rating: null,
        docRating: null,
        marketRating: null,
        whaleRating: null,
        amount: parseInt(asset.balance, 10)/(10 ** (asset.decimals || 0)),
        price: null,
        value: null,
        lastPurchased: null,
        more: null,
        failure: null
    }
}

const AssetGrid = (props: AssetGridProps): JSX.Element => {
    // useEffect(() => {
    //     const fetchRisks = async () => {
    //         const hexChainId = web3React.chainId ? web3React.chainId.toString(16) : '1'
    //         const fetchedRisks = props.assets.reduce((memo, asset) => {
    //             try {
    //                 const response = ky.post(`${process.env.REACT_APP_FIDESIUM_URL}/api/v0/0x${hexChainId}/contract`, {json: {contract: asset.token_address}, timeout: 30000})
    //                 return {
    //                     ...memo,
    //                     [asset.token_address]: response
    //                 }
    //             } catch (e) {
    //                 return {
    //                     ...memo,
    //                     [asset.token_address]: e
    //                 }
    //             }
    //         }, {})
    //         setFetchRisksNecessary(false)
    //         setFetchedRisks(fetchedRisks)
    //     }
    //     if(fetchRisksNecessary) {
    //         fetchRisks()
    //         setFetchRisksNecessary(false)
    //     }
    // }, [fetchRisksNecessary, fecthedRisks])
    // const columns: GridColDef<Row>[] = [
    //     {field: 'icon', headerName: '', width: 100, renderCell: (params: GridRenderCellParams) => {
    //         if ((params.row.icon !== null) && (params.row.icon !== undefined)) {
    //             return (
    //                 <img
    //                     src={params.row.icon}
    //                 ></img>
    //             )
    //         } else {
    //             return null
    //         }
    //     }},
    //     {field: 'symbol', headerName: 'Symbol', width: 100, sortable: false},
    //     {field: 'address', headerName: 'Address', width: 100, renderCell: (params: GridRenderCellParams) => {
    //         return (
    //             <div>{params.row.address}<ContentCopyIcon sx={{ display: 'block', fontSize: 14, "&:hover": {cursor: "pointer"} }} onClick={() => copy(params.row.address)}/></div>
    //         )
    //     }},
    //     {field: 'rating', headerName: 'Rating', width: 100, colSpan: ({row}) => {
    //         console.log(row)
    //         return 2
    //     }},
    //     {field: 'docRating', headerName: '(D)', width: 100},
    //     {field: 'marketRating', headerName: '(M)', width: 100},
    //     {field: 'whaleRating', headerName: '(W)', width: 100},
    //     {field: 'amount', headerName: 'Amount', width: 100},
    //     {field: 'price', headerName: 'Price', width: 100},
    //     {field: 'value', headerName: 'Value', width: 100},
    //     {field: 'lastPurchased', headerName: 'Last Purchased', width: 100},
    //     {field: 'more', headerName: 'More', width: 100, renderCell: (params: GridRenderCellParams) => {
    //         return (
    //             <Link to={`/token/${params.row.address}`}>View detail</Link>
    //         )
    //     }}
    // ]
    const processedRows = props.assets.map(constructRow)
    const [rows, _setRows] = useState<ReadonlyArray<AssetGridRowType>>(processedRows)

    // return (<DataGrid 
    //     columns={columns}
    //     initialState={{
    //         sorting: { sortModel: [{ field: 'amount', sort: 'desc' }] }
    //     }}
    //     slots={{
    //         loadingOverlay: LinearProgress,
    //         noRowsOverlay: EmptyWalletGridOverlay,
    //         row:
    //     }}
    //     slotProps={{
    //         loadingOverlay: {
    //             sx: {
    //                 height: '8px'
    //             }
    //         }
    //     }}
    //     rows={rows}
    //     loading={loading}
    //     rowSelection={false}
    //     disableRowSelectionOnClick={false}
    //     hideFooterSelectedRowCount={true}
    //     sx={{
    //         '.MuiDataGrid-columnSeparator': {
    //           display: 'none',
    //         },
    //       }}
    //     />)

    const columns: ReadonlyArray<Column> = [
        {name: 'failure'},
        {name: 'icon'}, //, width: 100, renderCell: (params: GridRenderCellParams) => {
        //     if ((params.row.icon !== null) && (params.row.icon !== undefined)) {
        //         return (
        //             <img
        //                 src={params.row.icon}
        //             ></img>
        //         )
        //     } else {
        //         return null
        //     }
        // }},
        {name: 'symbol', title: 'Symbol'},// width: 100, sortable: false},
        {name: 'address', title: 'Address'},// width: 100, renderCell: (params: GridRenderCellParams) => {
        // return (
        // <div>{params.row.address}<ContentCopyIcon sx={{ display: 'block', fontSize: 14, "&:hover": {cursor: "pointer"} }} onClick={() => copy(params.row.address)}/></div>
        // )
        // }},
        {name: 'rating', title: 'Rating'},// width: 100, colSpan: ({row}) => {
        // console.log(row)
        // return 2
        // }},
        {name: 'docRating', title: '(D)'},// width: 100},
        {name: 'marketRating', title: '(M)'},// width: 100},
        {name: 'whaleRating', title: '(W)'},// width: 100},
        {name: 'amount', title: 'Amount'},// width: 100},
        {name: 'price', title: 'Price'},// width: 100},
        {name: 'value', title: 'Value'},// width: 100},
        {name: 'lastPurchased', title: 'Last Purchased'},// width: 100},
        {name: 'more', title: 'More'},// width: 100, renderCell: (params: GridRenderCellParams) => {
        // return (
        // <Link to={`/token/${params.row.address}`}>View detail</Link>
        // )
        // }}
    ]
    //   const commitChanges = (changes: ChangeSet): void => {
    //     const changed = changes.changed
    //     if (changed && changed.length > 0) {
    //       setRows(rows.map(row => {
    //         return changed[row.id] ? {...row, ...changed[row.id]} : row
    //       }))
    //     }
    //   };

    return(
        <Grid
            rows={(rows.length === 0) ? processedRows : rows}
            columns={columns}
        >        
            <SortingState
                defaultSorting={[{ columnName: 'symbol', direction: 'asc' }]}
            />
            <IntegratedSorting />
            <PagingState
                defaultCurrentPage={0}
                defaultPageSize={25}
            />
            <IntegratedPaging />
            <Table 
                rowComponent={AssetGridRow}
                cellComponent={AssetGridCell}
            />
            <TableHeaderRow 
                contentComponent={AssetGridTableHeaderCell}
                showSortingControls
            />
            <PagingPanel />
        </Grid>
    )
}

export default AssetGrid