
import {JSX, PropsWithChildren} from 'react'
import {
    Table
} from '@devexpress/dx-react-grid-material-ui';
import { AssetGridRow as AssetGridRowType} from '../../types/AssetGrid/AssetGridRow';

type Props = PropsWithChildren<
    Readonly<{
        row: AssetGridRowType,
        style?: undefined,
        tableRow: Readonly<{
            row?: AssetGridRowType,
            rowId?: string | number | undefined,
            type: symbol,
            key: string
        }>
    }>
>
const AssetGridRow = (props: Props): JSX.Element => {
    const {children, ...restProps} = props
    return (
        <Table.Row {...restProps}>{children}</Table.Row>
    )
}
    
export default AssetGridRow