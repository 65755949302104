import {JSX, PropsWithChildren} from 'react'
import { sanitizeForId } from '../../utils/stringUtils'
import Grid from '@mui/material/Grid';


export type AssessmentTabProps = PropsWithChildren<{
    score: number | 'N/A',
    label: string
}>

const AssessmentTab = (props: AssessmentTabProps): JSX.Element => {
    return   <Grid  container item sm={12}
        className="tab-panel"
        role="tabpanel"
        aria-labelledby={`tab-${sanitizeForId(props.label)}`}
        id={`panel-${sanitizeForId(props.label)}`}
    >
        {props.children}
    </Grid>
}

export default AssessmentTab