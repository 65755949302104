import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import ER from '../../assets/fidesium.png';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Screen404 = () => {

    const [renavigate, setRenavigate] = useState(false)
    const navigate = useNavigate()
    
    useEffect(() => {
        if(renavigate === true) {
            navigate(`/`)
            setRenavigate(false)
        }
    }, [renavigate])

    return (
        <Container maxWidth="sm">
            <Grid container className='error-code' my={2}>
                <Grid item xs={9} className='error-text'>
                    <Box><h1>404</h1></Box>
                    <Box><h4>You have reached this page in error</h4></Box>
                </Grid>
                <Grid item xs={3}><img className="logo" src={ER} alt="Fidesium logo"/></Grid>
            </Grid>
            <Grid container my={2}>
                <Button variant="contained" color="primary" fullWidth={true} size="large" onClick={() => setRenavigate(true)}>Return to app</Button>
            </Grid>
        </Container>
    );
}

export default Screen404;
