import '../../App.scss';
import {useState, useEffect, JSX, ReactNode} from 'react'
import ky from 'ky'
import fl from '../../assets/fidesium-logo-white.png'
import audits from '../../assets/audits.png';
import extension from '../../assets/Extension-elon.png';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import { useWeb3React } from '@web3-react/core'
import { Injected } from '../../wallets/injectedWallet';
import { networkMap } from '../../stores/networkMap'
import {DialogModal} from '../../components/DialogModal/DialogModal'
import { useParams, useNavigate } from "react-router-dom"
import { useIsFirstRender } from 'usehooks-ts'
import ReactGA from "react-ga4";
import TokenSearchComponent from '../../components/TokenSearchComponent/TokenSearchComponent'
import {InnerDataBox} from '../../components/InnerDataBox/InnerDataBox'
import { DataBoxWrapper } from '../../components/DataBoxWrapper/DataBoxWrapper';

type Detector = Readonly<{
    impact: 'High' | 'Medium' | 'Low',
    check: string
}>

type ReturnedPrices = Array<
    Readonly<{
        value: null | number,
        time: string
    }>
>

type ReturnedRisk = Readonly<{
    contract: string,
    creator: string,
    fdv: number,
    marketCap: number,
    price: number,
    blockDiff: number,
    cmcMetadata: Readonly<{
        url: string | null,
        twitter: string | null,
        chat: string | null,
        source: string | null,
        description: string | null
    }>,
    launchBlock: number,
    creatorSanctioned: boolean,
    contractSanctioned: boolean,
    risks: Readonly<{
        name: string,
        risks: Readonly<{
            whaleRisk: boolean,
            unburnedLiquidityRisk: boolean,
            liquidityRisk: boolean,
            marketCapRisk: boolean,
            deployerWhitelisted: boolean,
            abiRisk: boolean,
            totalRisk: number,
            proxyRisk: boolean
        }>
    }>,
    staticAnalysis: null | Readonly<{
        error: null | string,
        success: boolean,
        results: Readonly<{
            detectors: ReadonlyArray<Detector>
        }>
    }>
}>

const TokenScreen = (): JSX.Element => {
    const { searchString } = useParams()
    const [searchValue, setSearchValue] = useState<string>(searchString || '')
    const [currentRisk, setCurrentRisk] = useState<null | ReturnedRisk>(null)
    const [fetchNecessary, setFetchNeccessary] = useState(searchString ? true : false)
    const [isFetching, setIsFetching] = useState(searchString ? true : false)
    const [fetchFailed, setFetchFailed] = useState(false)
    const [fetchFidCoin, setFetchFidCoin] = useState<boolean>(false)
    const [fidcoin, setFidcoin] = useState<number>(0)
    const [isDialogOpened, setIsDialogOpened] = useState(false)
    const [renavigate, setRenavigate] = useState(false)
    const web3React = useWeb3React()
    const navigate = useNavigate()
    const [submitDialogOpened, setSubmitDialogOpened] = useState(false)
    const [submitTokenPurchased, setSubmitTokenPurchased] = useState(false)
    const [submitWalletActivated, setSubmitWalletActivated] = useState(false)
    const [priceFetchNecessary, setPriceFetchNecessary] = useState(false)
    const [_priceFetchFailed, setPriceFetchFailed] = useState(false)
    const [priceHistory, setPriceHistory] = useState<null | ReturnedPrices>(null)
    const [launchBlock, setLaunchBlock] = useState<null | number>(null)
    const [_fdv, setFdv] = useState<number>(0)
    const [_marketCap, setMarketCap] = useState<number>(0)
    const isFirst = useIsFirstRender()
    


    useEffect(() => {
        const quantifyTransaction = async () => {
            try {
                const hexChainId = web3React.chainId ? web3React.chainId.toString(16) : '1'
                setFetchFailed(false)
                const response = await ky.post(`${process.env.REACT_APP_FIDESIUM_URL}/api/v0/0x${hexChainId}/contract`, {json: {contract: searchValue}, timeout: 30000})
                const responseBody: ReturnedRisk = await response.json()
                setCurrentRisk(responseBody as ReturnedRisk)
                setLaunchBlock((responseBody as ReturnedRisk).launchBlock)
                setFdv((responseBody as ReturnedRisk).fdv)
                setMarketCap((responseBody as ReturnedRisk).marketCap)
                setIsFetching(false)
                setPriceFetchNecessary(true)
            } catch (e) {
                setFetchFailed(true)
                setPriceFetchNecessary(false)
                console.log('error fetching', e)
            }
        }
        if(fetchNecessary) {
            quantifyTransaction()
            setFetchNeccessary(false)
        }
    }, [fetchNecessary, searchValue])

    useEffect(() => {
        const fetchPrices = async () => {
            try {
                const hexChainId = web3React.chainId ? web3React.chainId.toString(16) : '1'
                const response = await ky.post(`${process.env.REACT_APP_FIDESIUM_URL}/api/v0/0x${hexChainId}/priceHistory`, {json: {contract: currentRisk?.contract, startBlock: launchBlock}, timeout: 30000})
                const responseBody = await response.json()
                setPriceHistory(responseBody as ReturnedPrices)
                setPriceFetchFailed(false)
            } catch (e) {
                setPriceFetchFailed(true)
                console.log('error fetching', e)
            }
        }
        if(priceFetchNecessary) {
            fetchPrices()
            setPriceFetchNecessary(false)
        }
    }, [priceFetchNecessary, launchBlock, currentRisk])

    useEffect(() => {
        if(renavigate) {
            navigate(`/token/${searchValue}`)
        }
    }, [renavigate, searchValue])

    useEffect(() => {
        if(isFirst) {
            ReactGA.event('route_loaded', {value: 'tokens', token_value: searchString})
            if (web3React.active) {
                setFetchFidCoin(true)
            }
        }
    }, [isFirst])


    useEffect(() => {
        const fetchFidcoinHoldings = async () => {
            try {
                if (web3React.active) {
                    const response = await ky.get(`${process.env.REACT_APP_FIDESIUM_URL}/api/v0/fidcoin/${web3React.account}`)
                    const responseBody = await response.json() as Readonly<{fidcoin: number}>
                    setFidcoin(responseBody.fidcoin)
                }
                setFetchFidCoin(false)
            } catch (e) {
                setFetchFailed(true)
                console.log('error fetching', e)
            }
        }
        if(fetchFidCoin) {
            fetchFidcoinHoldings()
            setFetchFidCoin(false)
        }
    }, [fetchFidCoin, fidcoin])

    useEffect(() => {
        if(submitDialogOpened) {
            ReactGA.event('purchase_form_opened', {value: searchValue })
            setSubmitDialogOpened(false)
        }
    }, [searchValue, submitDialogOpened])

    useEffect(() => {
        if(submitTokenPurchased) {
            ReactGA.event('purchase_form_submitted', { value: searchValue })
            setSubmitDialogOpened(false)
        }
    }, [searchValue, submitDialogOpened])

    useEffect(() => {
        if(submitWalletActivated) {
            ReactGA.event('wallet_connect')
            setSubmitWalletActivated(false)
        }
    }, [submitDialogOpened])

    const triggerSearch = (val: string): void => {
        setSearchValue(val)
        setIsFetching(true)
        setFetchNeccessary(true)
        setRenavigate(true)
    }

    const onPurchase = () => {
        setIsDialogOpened(false)
        setSubmitTokenPurchased(true)
        setFetchFidCoin(true)
    }

    const maybePurchase = async () => {
        if(!web3React.active) {
            await web3React.activate(Injected)
            setSubmitWalletActivated(true)
            setFetchFidCoin(true)
        } else {
            setIsDialogOpened(true)
            setSubmitDialogOpened(true)
        }
    }

    const alterConnection = async () => {
        if (web3React.active) {
            web3React.deactivate()
        } else {
            setSubmitWalletActivated(true)
            await web3React.activate(Injected)
            setFetchFidCoin(true)
        }
    }

    const renderDialogModal = (): ReactNode => {
        if (currentRisk === null) {
            return null
        } else {
            return (<DialogModal
                isOpened={isDialogOpened}
                onProceed={onPurchase}
                onClose={() => setIsDialogOpened(false)}
                web3 = {web3React}
                contract= {currentRisk.contract}
                symbol={currentRisk.risks.name}
            ></DialogModal>)
        }
    }

    const renderFidCoinCount = (): ReactNode => {
        if(web3React.active) {
            return(<div className="fidCoin">{fidcoin} $FID</div>)
        } else {
            return null
        }
    }

    const renderDefaultContent = () => {
        if ((searchValue === '') || (searchValue === null) || (searchValue === undefined)) {
            return (<>
                <DataBoxWrapper title='Fidesium'>
                    <InnerDataBox xs={12} md={6} wrapperClass='add'>
                        <Grid container>
                            <Grid className="image" xs={6}>
                                <img className="add-image" src={audits} alt="Fidesium audits"/>
                            </Grid>
                            <Grid className="body" xs={6}>
                                <h4>Real time audits</h4>
                                <p>Protect your project now</p>
                                <p>AML and bad actor monitoring</p>
                                <p>Usage and traffic insights</p>
                                <Button variant="contained" size="small">Learn More</Button>
                            </Grid>
                        </Grid>
                    </InnerDataBox>
                    <InnerDataBox xs={12} md={6} wrapperClass='add'>
                        <Grid container>
                            <Grid className="image" xs={6}>
                                <img className="add-image" src={extension} alt="Fidesium Extension"/>
                            </Grid>
                            <Grid className="body" xs={6}>
                                <h4>Get protected</h4>
                                <p>Protect your wallet</p>
                                <p>Block malicious scripts</p>
                                <p>Secure your assets</p>
                                <Button variant="outlined" size="small" className="install">Install Now</Button>
                            </Grid>
                        </Grid>
                    </InnerDataBox>
                </DataBoxWrapper>
            </>)
        } else {
            return null
        }
    }

    return (
        <div className="wrapper">
            <AppBar className="newAppBar" elevation={0}>
                <Toolbar className="newToolBar">
                    <Box className="logo"><img className="logo" src={fl} alt="Fidesium logo"/></Box>
                    <Box className="title" sx={{ display: { xs: 'none', sm: 'block' } }}>Connected Account: <span>{ web3React.active ? web3React.account : '' }</span></Box>
                    <Box className="title" sx={{ display: { xs: 'none', sm: 'block' } }}>Connected Network: <span>{ (web3React.active && (web3React.chainId !== null) && (web3React.chainId !== undefined)) ? networkMap[web3React.chainId] : '' }</span></Box>
                    <Box className="fid" sx={{ display: { xs: 'none', sm: 'block' } }}>{renderFidCoinCount()}</Box>
                    <Button variant="contained" sx={{ display: { xs: 'none', sm: 'block' } }} color="primary" size="small" onClick={alterConnection}>{web3React.active? 'Disconnect Wallet' : 'Connect wallet'}</Button>
                </Toolbar>
            </AppBar>
            <Container className="tokenResearchWrapper">
                <TokenSearchComponent
                    tokenSearchFunc={triggerSearch}
                    currentRisk={currentRisk}
                    displayPurchase={true}
                    purchaseHandler={maybePurchase}
                    web3Active={web3React.active}
                    renderLoading={isFetching}
                    fetchFailed={fetchFailed}
                    searchString={searchValue}
                    pricingHistory={priceHistory || []}
                ></TokenSearchComponent>
                {renderDialogModal()}
                {renderDefaultContent()}
            </Container>
        </div>
    );
}

export default TokenScreen;
