

import fl from '../../assets/fidesium-logo-white.png'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {useState, useEffect, JSX} from 'react'
import { useParams } from "react-router-dom"
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import AssetGrid from '../../components/AssetGrid/AssetGrid'
import ky from 'ky'

type Holding = Readonly<{balance: string, decimals: number, logo: null | string, name: string, possible_spam: boolean, symbol: string, thumbnail: string, token_address: string}>

const WalletScreen = (): JSX.Element => {
    const { address } = useParams()
    const [fetchNecessary, setFetchNeccessary] = useState(address ? true : false)
    const [currentErc20Holdings, setCurrentErc20Holdings] = useState<ReadonlyArray<Holding>>([])
    const [_, setFetchFailed] = useState(false)

    useEffect(() => {
        const fetchHoldings = async () => {
            try {
                setFetchFailed(false)
                const response = await ky.post(`${process.env.REACT_APP_FIDESIUM_URL}/api/v0/0x1/wallet/holdings`, {json: {address}, timeout: 30000})
                const responseBody = await response.json() as Readonly<{holdings: Readonly<{erc20Holdings: ReadonlyArray<Holding>}>}>
                setCurrentErc20Holdings(responseBody.holdings.erc20Holdings || responseBody.holdings)
            } catch (e) {
                setFetchFailed(true)
                console.log('error fetching', e)
            }
        }
        if(fetchNecessary) {
            fetchHoldings()
            setFetchNeccessary(false)
        }
    }, [fetchNecessary, address])

    return (
        <div className="wrapper">
            <AppBar className="newAppBar" elevation={0}>
                <Toolbar className="newToolBar">
                    <Box className="logo"><img className="logo" src={fl} alt="Fidesium logo"/></Box>
                </Toolbar>
            </AppBar>
            <Container>
                <Grid container mt={10}>
                    <Grid item xs={12} className='walletLookup'>
                        <AssetGrid assets={currentErc20Holdings} wallet={address}/>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default WalletScreen;