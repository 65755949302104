import { useState, useEffect, SyntheticEvent } from "react";
import './DialogModal.css';
import {Grid, Button, Box} from '@mui/material';
import {whitelist as TokenWhitelist} from './tokenWhitelist'
import { Dialog, DialogContent, DialogTitle, NativeSelect, OutlinedInput, TextField } from "@mui/material";
import erc20abi from './erc20abi.json'
import * as ethers from 'ethers'
import ky from 'ky'
import { Web3ReactContextInterface } from "@web3-react/core/dist/types";
import { SwapVert as SwapVertIcon } from '@mui/icons-material';

const prov = (window.ethereum === undefined) ? null : new ethers.BrowserProvider(window.ethereum)

const prefixMap: Record<number, string> = {
    1: 'https://api.0x.org',
    5: 'https://goerli.api.0x.org'
}

const fidesiumWallet = '0x014e8a3F83821028435D7dF4e90c21a91D993A97'

type Props = {
  isOpened: boolean;
  onProceed: () => void;
  onClose: () => void;
  web3: Web3ReactContextInterface;
  contract: string;
  symbol: string;
};

const DialogModal = ({
    isOpened,
    onProceed,
    onClose,
    web3,
    contract,
    symbol
}: Props) => {
    const [buyWith, setBuyWith] = useState('ETH');
    const [amount, setAmount] = useState(0);
    const [decimals, setDecimals] = useState(18);
    const [rawAmount, setRawAmount] = useState(0);
    const [dollarAmount, setDollarAmount] = useState<number>(0)
    const [holdings, setHoldings] = useState(0);
    const [getHoldingsNecessary, setGetHoldingsNecessary] = useState(true);
    const [fetchPriceNecessary, setFetchPriceNecessary] = useState(false);
    const [fetchTargetDecimalsNecessary, setFetchTargetDecimalsNecessary] = useState(true)
    const [targetDecimals, setTargetDecimals] = useState(18)
    const [buyAmount, setBuyAmount] = useState(0)
    const [unprocessedConversionAmount, setUnprocessedConversionAmount] = useState('0')

    useEffect(() => {
        const getDecimals = async () => {
            if (prov !== null) {
                const contr = new ethers.Contract(contract, erc20abi, prov)
                const dec = await contr.decimals()
                setTargetDecimals(Number(dec))
            }
        }
        if(fetchTargetDecimalsNecessary) {
            getDecimals()
            setFetchTargetDecimalsNecessary(false)
        }
    }, [fetchTargetDecimalsNecessary])

    useEffect(() => {
        const getHoldings = async () => {
            if(prov !== null){
                if ( buyWith === 'ETH') {
                    const balance = await web3.library.currentProvider.request({method: 'eth_getBalance', params: [web3.account, 'latest']})
                    setHoldings(parseInt(balance, 16)/10**18)
                    setDecimals(18)
                } else {
                    const contr = new ethers.Contract(buyWith, erc20abi, prov)
                    const [tokenHoldings, dec] = await Promise.all([
                        contr.balanceOf(web3.account),
                        contr.decimals()
                    ])
                    const balanceFormatted = ethers.formatUnits(tokenHoldings, dec)
                    setDecimals(Number(dec))
                    setHoldings(parseInt(balanceFormatted, 10))
    
                }
                setFetchPriceNecessary(true)
            }
        }
        if(getHoldingsNecessary) {
            if (web3.account !== undefined) {
                getHoldings()
                setGetHoldingsNecessary(false)
            }
        }
    }, [buyWith, getHoldingsNecessary, web3.account, web3.chainId])


    const chain: number = (web3.chainId === undefined) ? 1 : web3.chainId
    const tokenWhitelist = TokenWhitelist[chain]
    const prefix: string = prefixMap[chain]

    useEffect(() => {
        const getPrice = async () => {
            if (rawAmount === 0) {
                return
            } else {
                const priceResponse = await ky.get(`${prefix}/swap/v1/price?sellToken=${buyWith}&buyToken=${contract}&sellAmount=${rawAmount}&takerAddress=${web3.account}`, {
                    headers: {
                        '0x-api-key': process.env.REACT_APP_0X_API_KEY
                    }
                })
                const jsonResponse: Record<string, string> = await priceResponse.json()
                setBuyAmount(parseInt(jsonResponse.buyAmount, 10)/(10 ** targetDecimals))
                if (buyWith !== '0xdac17f958d2ee523a2206206994597c13d831ec7') {
                    // const dollarPrice = await ky.get(`${prefix}/swap/v1/price?sellToken=0xdac17f958d2ee523a2206206994597c13d831ec7&buyToken=${contract}&sellAmount=${rawAmount}&takerAddress=${web3.account}`, {
                    //     headers: {
                    //         '0x-api-key': process.env.REACT_APP_0X_API_KEY
                    //     }
                    // })
                    // const jsonResponseDollars: Record<string, string> = await dollarPrice.json()
                    setDollarAmount(1000)
                } else {
                    setDollarAmount(buyAmount)
                }

            }
            
        }
        if(fetchPriceNecessary) {
            getPrice()
            setFetchPriceNecessary(false)
        }
    }, [rawAmount, fetchPriceNecessary])
    
    const proceedAndClose = async () => {
        if (prov !== null) {
            const fidesiumWallets = ['0x8dFCD143aaD420B512E9Bfd2300245d746cD6eDd', '0x6d82B45E9A59fbE228CF2373c6bC50667bb4C40F'].map((w) => w.toLowerCase())
            const quoteUrl =  ((web3.account !== undefined) && fidesiumWallets.includes(web3.account?.toLowerCase() || 'fake')) ? 
                `${prefix}/swap/v1/quote?sellToken=${buyWith}&buyToken=${contract}&sellAmount=${rawAmount}&takerAddress=${web3.account}` :
                `${prefix}/swap/v1/quote?sellToken=${buyWith}&buyToken=${contract}&sellAmount=${rawAmount}&takerAddress=${web3.account}&feeRecipient=${fidesiumWallet}&buyTokenPercentageFee=0.01&feeRecipientTradeSurplus=${fidesiumWallet}`
            const [quoteResponse, signer, baseGas ] = await Promise.all([ky.get(quoteUrl, {
                headers: {
                    '0x-api-key': process.env.REACT_APP_0X_API_KEY
                }
            }), prov.getSigner(), prov.getFeeData()])
            const quoteResponseJson: ethers.TransactionRequest = await quoteResponse.json()
            const tx = await signer.sendTransaction({...quoteResponseJson, gasPrice: Number(baseGas.gasPrice)} as ethers.TransactionRequest)
            await ky.post(`${process.env.REACT_APP_FIDESIUM_URL}/api/v0/transaction/submit`, {json: {
                tx_id: tx.hash,
                wallet_address: web3.account,
                purchasing_currency: buyWith,
                chain_id: '0x1',
                dollar_value: dollarAmount,
                purchased_contract: contract
            }, timeout: 30000})
            onProceed();
        }
        onClose();
    };

    const handleChange = (event: Readonly<{target: Readonly<{value: string}>}>) => {
        setGetHoldingsNecessary(true);
        setBuyWith(event.target.value);
    };

    const handleClose = (_: SyntheticEvent<unknown>) => {
        onClose()
    };

    const handleAmountChange = (event: Readonly<{target: Readonly<{value: string}>}>) => {
        setUnprocessedConversionAmount(event.target.value)
        if (event.target.value[event.target.value.length - 1] === '.') {
            return
        } else if(event.target.value[0] === '-') {
            setAmount(0)
            setRawAmount(0)
            setUnprocessedConversionAmount('0')
        } else if(event.target.value.length > 0) {
            const initialAmount = holdings
            const val = isNaN(Number(event.target.value)) ? 0 : parseFloat(event.target.value)
            if(val >= holdings) {
                setAmount(holdings)
                setUnprocessedConversionAmount(`${holdings}`)
                setRawAmount(val * (10 ** decimals))
            } else {
                setAmount(val)
                setRawAmount(val * (10 ** decimals))
            }
            if(initialAmount !== amount) {
                setFetchPriceNecessary(true)
            }
        }
    }

    return (
        <Dialog disableEscapeKeyDown open={isOpened} onClose={handleClose}>
            <DialogTitle className="swap-dialog">Swap</DialogTitle>
            <DialogContent className="swap-dialog">
                <Grid container mt={0} rowSpacing={0} className='dex'>
                    <Grid item xs={6}><Box className='tokenTitle'>Amount:</Box></Grid>
                    <Grid item xs={3}><Box className='tokenTitle'>Buy With:</Box></Grid>
                    <Grid item xs={3}><Box className='tokenTitle'>Balance:</Box></Grid>
                    <Grid item xs={6} className='first'>
                        <TextField className='swapInput inputAmount' data-testid='input-amount' id="outlined-basic" variant="outlined" onChange={handleAmountChange} value={unprocessedConversionAmount} type="number">
                        </TextField>
                    </Grid>
                    <Grid item xs={3} className='middle'>
                        <Box className='token'>
                            <NativeSelect 
                                value={buyWith}
                                onChange={handleChange}
                                className='swapInput'
                                input={<OutlinedInput label="Token" id="demo-dialog-native" />}
                            >
                                {tokenWhitelist.map(token => {
                                    return(<option value={token.contract} key={token.contract}>{token.symbol}</option>)
                                })}
                            </NativeSelect>
                        </Box>
                    </Grid>
                    <Grid item xs={3} className='last'>
                        <Box className='holdings'>{holdings.toFixed(4)}</Box>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} className='transaction-icon'><SwapVertIcon sx={{ fontSize: 24 }}/></Grid>
                </Grid>
                <Grid container mt={0} rowSpacing={0} className='dex'>
                    <Grid item xs={6}><Box className='tokenTitle'>Amount:</Box></Grid>
                    <Grid item xs={6}><Box className='tokenTitle'>Receive:</Box></Grid>
                    <Grid item xs={6} className='first receive'>
                        <Box className='buying'>{buyAmount.toFixed(4)}</Box>
                    </Grid>
                    <Grid item xs={6} className='last receive'>
                        <Box className='buying'>{symbol}</Box>
                    </Grid>
                </Grid>
                <Grid container spacing={1} mt={2}>
                    <Grid item xs={12}><Button className="prime" variant="contained" color="success" size="large" onClick={proceedAndClose} fullWidth disabled={amount <= 0}>Swap</Button></Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export {DialogModal};