import '../../App.scss';
import {useState, useEffect, JSX, ReactNode, ChangeEvent, ChangeEventHandler} from 'react'
import ky from 'ky'
import fl from '../../assets/fidesium-logo-white.png'
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useWeb3React } from '@web3-react/core'
import { Injected } from '../../wallets/injectedWallet';
import { networkMap } from '../../stores/networkMap'
import {InnerDataBox} from '../../components/InnerDataBox/InnerDataBox'
import { DataBoxWrapper } from '../../components/DataBoxWrapper/DataBoxWrapper';
import { useIsFirstRender } from 'usehooks-ts'
import { AddressSearchBar } from '../../components/AddressSearchBar/AddressSearchBar';
import ReactGA from "react-ga4";
import { WrappedTokenRiskAccordion } from '../../components/WrappedTokenRiskAccordion/WrappedTokenRiskAccordion';
import { TextField, NativeSelect, OutlinedInput } from '@mui/material';
import erc20abi from '../../components/DialogModal/erc20abi.json'
import {whitelist as TokenWhitelist} from '../../components/DialogModal/tokenWhitelist'
import * as ethers from 'ethers'

type AmlStatus = Readonly<{
    aml: Readonly<{
        readonly contractAddress: string,
        readonly contractCreator: string,
        readonly txHash: string,
        readonly contractSanctioned: boolean,
        readonly creatorSanctioned: boolean,
        readonly isContract: boolean
    }>
}>

const prov = (window.ethereum === undefined) ? null : new ethers.BrowserProvider(window.ethereum)

const SendScreen = (): JSX.Element => {
    const [buyWith, setBuyWith] = useState<string>('ETH');
    const [targetAddress, setTargetAddress] = useState<string>('')
    const [displayAddressSubmit, setDisplayAddressSubmit] = useState<boolean>(false)
    const [fetchAmlNecessary, setFetchAmlNecessary] = useState<boolean>(false)
    const [_fetchAmlFailed, setFetchAmlFailed] = useState<boolean>(false)
    const [amlStatus, setAmlStatus] = useState<AmlStatus | null>(null)
    const web3React = useWeb3React()
    const isFirst = useIsFirstRender()
    const [_holdingsFetchFailed, setHoldingsFetchFailed] = useState<boolean>(false)
    const [holdingsFetchNecessary, setHoldingsFetchNecessary] = useState<boolean>(false)
    const [holdings, setHoldings] = useState<number>(0)
    const [decimals, setDecimals] = useState<number>(0)
    const [amount, setAmount] = useState(0)
    const [issueSend, setIssueSend] = useState<boolean>(false)

    const chain: number = (web3React.chainId === undefined) ? 1 : web3React.chainId
    const tokenWhitelist = TokenWhitelist[chain]

    useEffect(() => {
        const actuallySend = async () => {
            if (prov !== null) {
                const signer = await prov.getSigner()
                if (buyWith === 'ETH') {
                    await signer.sendTransaction({
                        to: targetAddress,
                        value: ethers.parseEther(`${amount}`)
                    })
                } else {
                    const contract = new ethers.Contract(buyWith, erc20abi, prov)
                    const contractSigner = contract.connect(signer) as ethers.BaseContract & Readonly<{transfer: (arg0: string, arg1: bigint) => Promise<void>}>
                    await contractSigner.transfer(targetAddress, ethers.parseUnits(`${amount}`, decimals))
                }
            }
            setIssueSend(false)
        }

        if(issueSend === true) {
            actuallySend()
            setIssueSend(false)
        }
    }, [amount, issueSend, buyWith, targetAddress])

    useEffect(() => {
        const checkHoldings = async () => {
            try {
                if ( buyWith === 'ETH') {
                    const balance = await web3React.library.currentProvider.request({method: 'eth_getBalance', params: [web3React.account, 'latest']})
                    setHoldings(parseInt(balance, 16)/10**18)
                    setDecimals(18)
                } else {
                    const contr = new ethers.Contract(buyWith, erc20abi, prov)
                    const [tokenHoldings, dec] = await Promise.all([
                        contr.balanceOf(web3React.account),
                        contr.decimals()
                    ])
                    const balanceFormatted = ethers.formatUnits(tokenHoldings, dec)
                    setDecimals(Number(dec))
                    setHoldings(parseInt(balanceFormatted, 10))
                    setHoldingsFetchFailed(false)
                }
            } catch (e) {
                setHoldingsFetchFailed(true)
            }
        }
        if (holdingsFetchNecessary) {
            checkHoldings()
            setHoldingsFetchNecessary(false)
        }
    }, [holdingsFetchNecessary, buyWith, web3React.account])

    useEffect(() => {
        const checkAml = async () => {
            try {
                const hexChainId = web3React.chainId ? web3React.chainId.toString(16) : '1'
                setFetchAmlFailed(false)
                const response = await ky.get(`${process.env.REACT_APP_FIDESIUM_URL}/api/v0/0x${hexChainId}/address/${targetAddress}/aml`, {timeout: 30000})
                const responseBody = await response.json()
                setAmlStatus(responseBody as AmlStatus)
                setFetchAmlFailed(false)
            } catch (e) {
                setFetchAmlFailed(true)
                console.log('error fetching', e)
            }
        }
        if(fetchAmlNecessary) {
            checkAml()
            setFetchAmlNecessary(false)
        }
    }, [fetchAmlNecessary, targetAddress])

    useEffect(() => {
        if(isFirst) {
            ReactGA.event('route_loaded', {value: 'send'})
        }
    }, [isFirst])

    const triggerSearch = (addressValid: boolean, val: string) => {
        setDisplayAddressSubmit(addressValid)
        if (addressValid === true) {
            setTargetAddress(val)
        } else {
            setTargetAddress('')
        }
    }

    

    const renderAddressSubmit = (): ReactNode => {
        if (displayAddressSubmit === true) {
            return <Button onClick={validateAddress}>Check Wallet</Button>
        } else {
            return null
        }
    }

    const handleAssetChange: ChangeEventHandler<HTMLSelectElement> = (event: ChangeEvent<HTMLSelectElement>): void => {
        setHoldingsFetchNecessary(true);
        setBuyWith(event.target.value);
    }

    const renderAmlInfo = (): ReactNode => {
        if (amlStatus === null) { 
            return null
        } else {
            return(
                <><DataBoxWrapper title='Risk details'>
                    <InnerDataBox xs={12} md={6} wrapperClass='data'>
                        <WrappedTokenRiskAccordion 
                            accordionId='is-contract-accordion'
                            title='Address is not a contract:'
                            passCondition={!amlStatus.aml.isContract}
                            riskStatusId='is contract-risk-status'
                            baseContent='Smart contracts inherently carry higher risks than wallets and can be exploited or abused by counter parties'
                        >
                        </WrappedTokenRiskAccordion>
                        <WrappedTokenRiskAccordion 
                            accordionId='address-sanctioned-accordion'
                            title='Address has not been sanctioned'
                            passCondition={!amlStatus.aml.contractSanctioned}
                            riskStatusId='deployer-risk-status'
                            baseContent={null}
                        >
                        </WrappedTokenRiskAccordion>
                        {amlStatus.aml.isContract ? <WrappedTokenRiskAccordion 
                            accordionId='deployer-sanctioned-accordion'
                            title='Deployer has been sanctioned:'
                            passCondition={amlStatus.aml.creatorSanctioned}
                            riskStatusId='deployer-sanctioned-status'
                            baseContent={'The contract deployer has been sanctiond'}
                        >
                        </WrappedTokenRiskAccordion> : null}
                    </InnerDataBox>
                </DataBoxWrapper>
                <span>Send to {amlStatus.aml.contractAddress}</span>
                <NativeSelect 
                    value={buyWith}
                    onChange={handleAssetChange}
                    className='swapInput'
                    input={<OutlinedInput label="Token" id="demo-dialog-native" />}
                >
                    {tokenWhitelist.map(token => {
                        return(<option value={token.contract} key={token.contract}>{token.symbol}</option>)
                    })}
                </NativeSelect>
                </>
            )
        }
    }

    const configureAmount = (evt: ChangeEvent<HTMLInputElement>): void => {
        setAmount(parseFloat(evt.target.value))
    }

    const sendAssets = (): void => {
        setIssueSend(true)
    }

    const renderSendingState = (): ReactNode => {
        console.log(amount)
        console.log(holdings)
        console.log(prov)
        if (web3React.active) {
            return (
                <>
                    <TextField type='number' className='address-search' placeholder='how much do you want to send' error={amount > holdings} onChange={configureAmount} helperText={holdings}></TextField>
                    {((amount < holdings) && (amount > 0) && (prov !== null)) ? <Button onClick={sendAssets}>Send Assets</Button> : null}
                </>
            )
        } else {
            return(<Button variant="contained" sx={{ display: { xs: 'none', sm: 'block' } }} color="primary" size="small" onClick={alterConnection}>{web3React.active? 'Disconnect Wallet' : 'Connect wallet'}</Button>)
        }

    }

    const validateAddress = (): void => {
        setFetchAmlNecessary(true)
    }
    const alterConnection = () => {
        if (web3React.active) {
            web3React.deactivate()
        } else {
            web3React.activate(Injected)
        }
    }
    return (
        <div className="wrapper">
            <AppBar className="newAppBar" elevation={0}>
                <Toolbar className="newToolBar">
                    <Box className="logo"><img className="logo" src={fl} alt="Fidesium logo"/></Box>
                    <Box className="title" sx={{ display: { xs: 'none', sm: 'block' } }}>Connected Account: <span>{ web3React.active ? web3React.account : '' }</span></Box>
                    <Box className="title"  sx={{ display: { xs: 'none', sm: 'block' } }}>Connected Network: <span>{ (web3React.active && (web3React.chainId !== null) && (web3React.chainId !== undefined)) ? networkMap[web3React.chainId] : '' }</span></Box>
                    <Button variant="contained" sx={{ display: { xs: 'none', sm: 'block' } }} color="primary" size="small" onClick={alterConnection}>{web3React.active? 'Disconnect Wallet' : 'Connect wallet'}</Button>
                </Toolbar>
            </AppBar>
            <Container>
                <Grid container mt={10}>
                    <Grid item xs={12} className='tokenLookup'>
                        <DataBoxWrapper title='Check assets for transfer'>
                            <InnerDataBox xs={12} md={12} wrapperClass='data'>
                                <AddressSearchBar triggerSearch={triggerSearch}/>
                                {renderAddressSubmit()}
                                {renderAmlInfo()}
                                {renderSendingState()}
                            </InnerDataBox>
                        </DataBoxWrapper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default SendScreen;
