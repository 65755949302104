import '../../App.scss';
import { ethers } from 'ethers';
import ky from 'ky';
import fl from '../../assets/fidesium-logo-white.png'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import {useState, useEffect, JSX, ReactNode} from 'react'
import { useParams } from "react-router-dom"
import { Provider, types } from "zksync-ethers"
import AssessmentTab from '../../components/AssessmentTab/AssessmentTab';
import AssessmentTabList from '../../components/AssessmentTabList/AssessmentTabList';
import AssessmentVersionOne from '../../components/AssessmentVersionOne/AssessmentVersionOne';


const selectRiskClass = (score: number | 'N/A') => {
    if (score === 'N/A') {
        return 'na'
    } else if (score <= 10) {
        return 'ten'
    } else if (score <= 20) {
        return 'twenty'
    } else if (score <= 30) {
        return 'thirty'
    } else if (score <= 40) {
        return 'forty'
    } else if (score <= 50) {
        return 'fifty'
    } else if (score <= 60) {
        return 'sixty'
    } else if (score <= 70) {
        return 'seventy'
    } else if (score <= 80) {
        return 'eighty'
    } else {
        return 'ninety'
    }
}

const abi = ["function tokenURI(uint256) public view returns (string)"]

const contractAddress = '0x56E3cB1C9C8ae6Ca71578713fde0d37b799dC589'


const renderAmlWalletChains = (walletChains: Record<string, any>): ReactNode => {
    if (Object.keys(walletChains).length > 0) {
        return Object.keys(walletChains).map(key => {
            return(<>
                <Grid className="field" item xs={4} md={4}>Transaction id:</Grid>
                <Grid className="data" item xs={8} md={8}>{key}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Transaction age: </Grid>
                <Grid className="data" item xs={8} md={8}>{walletChains[key].age}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Transaction amount:</Grid>
                <Grid className="data" item xs={8} md={8}>{walletChains[key].amount} ETH</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Transaction source:</Grid>
                <Grid className="data" item xs={8} md={8}>{walletChains[key].exchangeTag}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Transaction source issue:</Grid>
                <Grid className="data" item xs={8} md={8}>{walletChains[key].exchangeIssue}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Transaction impact:</Grid>
                <Grid className="data" item xs={8} md={8}>{walletChains[key].impact}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Transaction relevance chain:</Grid>
                <Grid className="data" item xs={8} md={8}>{walletChains[key].walletChain}</Grid>
            </>)
        })
    } else {
        return null
    }
}

const renderWhaleFundingList = (fundingList: Record<string, ReadonlyArray<string>>, key: string): ReactNode => {
    if (fundingList) {
        const fundingDisplay = Object.keys(fundingList).map((funder, index) => {
            return (<>
                <Grid className="field" item xs={4} md={4}>Funding source:</Grid>
                <Grid className="data" item xs={8} md={8} key={key + '-whale-funder'}>{funder}</Grid>
                <Grid className="field inner" item xs={4} md={4}>Transaction(s):</Grid>
                <Grid className="data" item xs={8} md={8}>
                    {fundingList[funder].map((tx) => {
                        return(<Box key={key + '-whale-funder-tx-' + tx}>{tx}</Box>)
                    })}
                </Grid>
                {(index === Object.keys(fundingList).length -1) ? <></> : <hr className="sub"/>}
            </>)
        })
        return fundingDisplay
    } else {
        return <></>
    }
}

const renderWhaleSybilRisks = (sybilled: any, key: string): ReactNode => {
    if (sybilled && !Array.isArray(sybilled)) {
        const sybilKeys = Object.keys(sybilled)
        const sybilGrid = sybilKeys.map((sybilTx) => {
            return (<>
                <Grid className="field" item xs={4} md={4}>Whale is sybilled with other whales:</Grid>
                <Grid className="data" item xs={8} md={8}></Grid>
                <Grid className="field inner" item xs={4} md={4}>Transaction(s): </Grid>
                <Grid className="data" item xs={8} md={8}>
                    {sybilTx}
                </Grid>
                <Grid className="field inner" item xs={4} md={4}>Whales: </Grid>
                <Grid item className="data" xs={8} md={8}>
                    {
                        sybilled[sybilTx].map((sybil: any) => {
                            return <span key={`${key}-sybilled-whale-${sybil}`}> {sybil}</span>
                        })}
                </Grid>
                <hr></hr>
            </>)
        })
        return sybilGrid
    } else if (sybilled && Array.isArray(sybilled) && sybilled.length > 0) {
        return (<><Grid className="field" item xs={4} md={4}>Whale is sybilled with other whales:</Grid><Grid className="data" item xs={8} md={8}>{(sybilled.map((sybil: any) => {
            return <span key={`${key}-sybilled-whale-${sybil}`}> {sybil}</span>
        }))
        }</Grid><hr/></>)
    } else {
        return <></>
    }
}

const renderWhaleRisks = (whaleRisks: any): ReactNode => {
    if (Object.keys(whaleRisks).length > 0) {
        return Object.keys(whaleRisks).map((key, index) => {
            return(<>

                <Grid className="split" item xs={12}>Whale {index +1}</Grid>
                
                <Grid className="field" item xs={4} md={4}>Whale address:</Grid>
                <Grid className="data" item xs={8} md={8}>{key}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Whale is a contract:</Grid>
                <Grid className="data" item xs={8} md={8}>{(!!whaleRisks[key].contract).toString()}</Grid>
                <hr/>

                {whaleRisks[key].contract ? <><Grid className="field" item xs={4} md={4}>Whale was deployed by:</Grid> <Grid className="data" item xs={8} md={8}>{whaleRisks[key].deployer}</Grid><hr/></> : ''}              

                {renderWhaleFundingList(whaleRisks[key].funding, key)}
                <hr/>
                <Grid className="field" item xs={4} md={4}>Whale is a proxy contract:</Grid>
                <Grid className="data" item xs={8} md={8}>{(!!whaleRisks[key].proxy).toString()}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Whale is holding:</Grid> 
                <Grid className="data" item xs={8} md={8}>{(whaleRisks[key].supply === 0) ? 'Less than 0.5%' : `${whaleRisks[key].supply*100}%`}</Grid>
                <hr/>

                {renderWhaleSybilRisks(whaleRisks[key].sybilled, key)}


                <Grid className="field" item xs={4} md={4}>Whale is a verified contract:</Grid>
                <Grid className="data" item xs={8} md={8}>{(!!whaleRisks[key].unverified).toString()}</Grid>
            </>)
        })
    } else {
        return null
    }

}

const renderSolcVersionVulnerability = (tRisk: any): JSX.Element => {
    return (<>
        <Grid className="field" item xs={4} md={4}>Solc Version Used:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.version}</Grid>
        <hr/>
    </>)
}

const renderMissingImmutableVulnerability = (tRisk: any): JSX.Element => {
    return (<>
        <Grid className="field" item xs={4} md={4}>Variable Affected:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.variable}</Grid>
        <hr/>
    </>)
}

const renderReentrancyVulnerability = (tRisk: any): JSX.Element => {
    return(<>

        <Grid className="field" item xs={4} md={4}>Function Affected:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.function}</Grid>
        <hr/>

        <Grid className="field" item xs={4} md={4}>External Contract called:</Grid>
        <Grid className="data" item xs={8} md={8}>{tRisk.contractCall}</Grid>
    </>)
}

const renderVulnerabilities = (techRisk: any): ReactNode => {
    if (techRisk.length > 0) {
        return techRisk.map((tRisk: any, index: number) => {
            return(<>

                <Grid className="split" item xs={12}>Vulnerability {index + 1}</Grid>
                
                <Grid className="field" item xs={4} md={4}>Vulnerability Summary:</Grid>
                <Grid className="data" item xs={8} md={8}>{tRisk.type}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Vulnerability impact:</Grid>
                <Grid className="data" item xs={8} md={8}>{tRisk.impact}</Grid>
                <hr/>

                {tRisk.type === 'reentrancy' ? renderReentrancyVulnerability(tRisk): ''}
                {tRisk.type === 'missingImmutable' ? renderMissingImmutableVulnerability(tRisk) : ''}
                {tRisk.type === 'solc_version' ? renderSolcVersionVulnerability(tRisk) : ''}
            </>)
        })
    } else {
        return null
    }
}

const renderReport = (metadata: any, index: number): JSX.Element => {
    const fullMetadataUri = `https://ipfs.io/ipfs/${metadata.ownUrl}`
    const fullReportUrl = `https://ipfs.io/ipfs/${metadata.report}`
    if (metadata.version === undefined) {
        return(<AssessmentVersionOne
            metadata={metadata} 
            fullMetadataUri={fullMetadataUri}
            fullReportUrl={fullReportUrl}
        ></AssessmentVersionOne>)
    } else {
        return(<AssessmentTabList activeTabIndex={0}>
            <AssessmentTab score={metadata.risk} label={'Report Summary'}>
                <Grid className="field" item xs={4} md={4}>Report on:</Grid>
                <Grid className="data" item xs={8} md={8}>{metadata.client}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Report block:</Grid>
                <Grid className="data" item xs={8} md={8}>{metadata.block}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Report chain:</Grid>
                <Grid className="data" item xs={8} md={8}>Ethereum Mainnet</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Risk summary:</Grid>
                <Grid className="data" item xs={8} md={8}><span className="riskScore-sm twenty-bg"><span>{metadata.risk}</span></span></Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Report number:</Grid>
                <Grid className="data" item xs={8} md={8}>{`Report Number: ${index}`}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Detailed Metadata Storage:</Grid>
                <Grid className="data" item xs={8} md={8}><a href={fullMetadataUri} target="_blank" rel="noreferrer">Permalink</a></Grid>
                <hr/>

                <Grid className="field" item xs={12} md={12}>This is a {metadata.prelaunch ? 'prelaunch' : 'post launch'} assessment</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>The root contract analyzed was:</Grid>
                <Grid className="data" item xs={8} md={8}>{metadata.contract}</Grid>
            </AssessmentTab>

            <AssessmentTab score={metadata.risks.aml.risk} label={'Aml Risk'}>
                {renderAmlWalletChains(metadata.risks.aml.walletChains)}
            </AssessmentTab>

            <AssessmentTab score={metadata.risks.team.risk} label={'Team Risk'}>
                <Grid className="field" item xs={4} md={4}>Team doxxing status: </Grid>
                <Grid className="data" item xs={8} md={8}>{metadata.risks.team['doxxed-public'] ? 'public' : (metadata.risks.team['doxxed'] ? 'private': 'anonymous')}</Grid>
                <hr/>

                <Grid className="field" item xs={4} md={4}>Team experience:</Grid>
                <Grid className="data" item xs={8} md={8}>{metadata.risks.team.experience}</Grid>
            </AssessmentTab>

            <AssessmentTab score={metadata.risks.liquidity.prelaunch ? 'N/A' : metadata.risks.team.risk} label={'Liquidity Risk'}>
                {metadata.risks.liquidity.prelaunch ? 'As this is a prelaunch assessment, liquidity analytics are not included' : ''}
            </AssessmentTab>

            <AssessmentTab score={metadata.risks.whales.risk} label={'Whale Risk'}>
                <Grid className="field" item xs={12} md={12}>{metadata.risks.whales.prelaunch ? 'As this is a prelaunch assessment, whale analytics are of limited value' : ''}</Grid>
                {renderWhaleRisks(metadata.risks.whales.whales)}
            </AssessmentTab>

            <AssessmentTab score={metadata.risks.technicalRisk.risk} label={'Contract Risk'}>
                <Grid className="field" item xs={4} md={4}>Contract is a Honeypot:</Grid>
                <Grid className="data" item xs={8} md={8}>{metadata.risks.technicalRisk.honeyPot.toString()}</Grid>

                <Grid className="field" item xs={4} md={4}>Contract has tax:</Grid>
                <Grid className="data" item xs={8} md={8}>{metadata.risks.technicalRisk.tax.toString()}</Grid>
                {renderVulnerabilities(metadata.risks.technicalRisk.vulnerabilities)}
            </AssessmentTab>

            <AssessmentTab score={metadata.risk} label={'Full Report'}>
                <Grid className="field" item xs={4} md={4}>Detailed Report Link:</Grid>
                <Grid className="data" item xs={8} md={8}><a href={fullReportUrl} target="_blank" rel="noreferrer">Report link</a></Grid>

                <Grid className="field" item xs={12}><iframe src={fullReportUrl} width="100%" height="400px"></iframe></Grid>
            </AssessmentTab>
        </AssessmentTabList>)
    }
}

const AssessmentScreen = (): JSX.Element => {

    const { assessmentId } = useParams()
    const [metadata, setMetadata] = useState<any>(null);
    const [previousReports, setPreviousReports] = useState<ReadonlyArray<any>>([])
    const [previousReportFetchNecessary, setPreviousReportFetchNecessary] = useState<boolean>(false)
    const [selectedReport, setSelectedReport] = useState<number>(0)
    useEffect(() => {
        const fetchHistory = async () => {
            const provider = Provider.getDefaultProvider(types.Network.Mainnet);
            const contract = new ethers.Contract(contractAddress, abi, provider)
            const mUri = await contract.tokenURI(assessmentId)
            const mData = await ky.get(`https://ipfs.io/ipfs/${mUri}`)
            const mDataBody = await mData.json()
            setMetadata({...(mDataBody as any), ownUrl: mUri})
            if ((mDataBody as any).previousReport) {
                setPreviousReportFetchNecessary(true)
            }
        }

        fetchHistory()
    }, [])

    useEffect(() => {
        const fetchPreviousReports = async () => {
            const rootMetadata = previousReports.length === 0 ? metadata : previousReports[previousReports.length -1]
            if (rootMetadata.previousReport !== null) {
                const mData = await ky.get(`https://ipfs.io/ipfs/${rootMetadata.previousReport}`)
                const mDataBody = await mData.json()
                const newMetadataHistory = [...previousReports, {...(mDataBody as any), ownUrl: rootMetadata.previousReport}]
                setPreviousReportFetchNecessary(false)
                setPreviousReports(newMetadataHistory)
                if ((mDataBody as any).previousReport !== null) {
                    setPreviousReportFetchNecessary(true)
                }
            } else {
                setPreviousReportFetchNecessary(false)
            }
        }

        if (previousReportFetchNecessary === true) {
            fetchPreviousReports()
        }
    }, [previousReportFetchNecessary, previousReports, metadata])

    const selectAudit = (index: number) => {
        setSelectedReport(index)
    }

    const renderPreviousReports = (allReports: ReadonlyArray<any>): ReactNode => {
        const previous = allReports.map((previousReport, index) => {
            console.log('jhere')
            const isSelectedReport = index === selectedReport
            const date = previousReport.date ? previousReport.date : (previousReport.version === 2) ? '03/07/2024' : '14/06/2024'
            return(<Grid item sm={12} md={12} className={'audit-selector ' + (isSelectedReport ? 'selected' : '')} key={`assessment-${index}`}>
                <div className={'auditBlock '+ (isSelectedReport ? 'selected' : '')} onClick={selectAudit.bind({}, index)}>
                    {previousReport.client}
                    <h4 className={`riskScore-sm ${selectRiskClass(previousReport.risk)}-bg`}><span>{previousReport.risk} / 100</span></h4>
                    <h3 className='auditDate'>{date}</h3>
                </div>
            </Grid>)

        })
    
        return(<>
            {previous}
        </>)
    }

    if(metadata === null) {
        return (<div></div>)
    } else {
        const allReports = [metadata, ...previousReports]


        return (
            <div className="wrapper">
                <AppBar className="newAppBar" elevation={0}>
                    <Toolbar className="newToolBar">
                        <Box className="logo"><img className="logo" src={fl} alt="Fidesium logo"/></Box>
                        <Box className="title"><span>Risk Posture Audits</span></Box>
                    </Toolbar>
                </AppBar>
                <Grid container spacing={2} my={8} className="auditBody" maxWidth='xs'>
                    <Grid item xs={12} md={12}>{renderPreviousReports(allReports)}</Grid>
                    {renderReport(allReports[selectedReport], allReports.length - selectedReport)}

                </Grid>
            </div>)
    }

}

export default AssessmentScreen;