import { createChart, ColorType, CrosshairMode } from 'lightweight-charts';
import { useEffect, useRef } from 'react';


type ChartingDisplayProps = Readonly<{
    pricingHistory: Array<Readonly<{time: string, value: number | null}>>
}>

const ChartingDisplay = (props: ChartingDisplayProps) => {
    const chartContainerRef = useRef<HTMLInputElement>(null);

    useEffect(
        () => {
            const filteredPrices = props.pricingHistory.filter(p => p.value !== null)
            if (!chartContainerRef || !chartContainerRef.current || (chartContainerRef.current === undefined)) {
                return
            } {
                const handleResize = () => {
                    chart.applyOptions({ width: chartContainerRef.current?.clientWidth || 200 });
                };
    
                const chart = createChart(chartContainerRef.current!, {
                    layout: {
                        background: { type: ColorType.Solid, color: 'black' },
                        textColor: 'green'
                    },
                    width: chartContainerRef.current?.clientWidth || 200,
                    height: 300,
                    crosshair: {
                        mode: CrosshairMode.Normal,
                    },
                    rightPriceScale: {
                        visible: true,
                        borderColor: 'rgba(197, 203, 206, 1)'
                    }
                });
                chart.timeScale().fitContent();
                const lastValue = filteredPrices[filteredPrices.length -1]
                const precision = ((lastValue?.value || 1) >= 1) ? 0 : 1-Math.floor(Math.log(lastValue?.value || 1)/Math.log(10))
                const minMove = (precision === 0) ? (lastValue?.value || 1)/(10 ** (Math.floor(Math.log(lastValue?.value || 1)/Math.log(10)) -1)) : 10 ** (Math.floor(Math.log(lastValue?.value || 1)/Math.log(10)) -1)
                const newSeries = chart.addAreaSeries({ lineColor: 'green', topColor: '#CC227f19', bottomColor: '#CC227f19', priceFormat: {
                    minMove,
                    precision
                } });
                newSeries.setData(filteredPrices);
    
                window.addEventListener('resize', handleResize);
    
                return () => {
                    window.removeEventListener('resize', handleResize);
    
                    chart.remove();
                };
            }
        },
        [props.pricingHistory, chartContainerRef]
    );
    return (<>
        <div className='chart' ref={chartContainerRef}>
        </div>
    </>)
}

export {ChartingDisplay}