import { Grid } from "@mui/material";
import { PropsWithChildren, ReactElement } from "react";

export type DataBoxWrapperProps = PropsWithChildren<Readonly<{
    title: string,
    className?: string
}>>

const DataBoxWrapper = (props: DataBoxWrapperProps): ReactElement => {
    return (
        <div className={`data-box-wrapper ${props.className ? props.className : ""}`}>
            <h2 className="wrapper-title">{props.title}</h2>
            <Grid container mt={2} className='scores'>
                {props.children}
            </Grid>
        </div>)
};

export {DataBoxWrapper};