import { Grid, Box } from "@mui/material";
import { PropsWithChildren, ReactElement } from "react";

export type InnerDataBoxProps = PropsWithChildren<Readonly<{
    wrapperClass: string,
    xs: number,
    md: null | number,

}>>

const InnerDataBox = (props: InnerDataBoxProps): ReactElement => {
    return (
        <Grid item xs={props.xs} md={(props.md === null) ? undefined : props.md} className={props.wrapperClass} data-testid="outer-data-box-grid">
            <Box className="dataBox" data-testid="inner-data-box">
                {props.children}
            </Box>
        </Grid>)
};

export {InnerDataBox};