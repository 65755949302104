const whitelist: Record<number, ReadonlyArray<Readonly<{contract: string, symbol: string}>>> = {
    1: [
        {
            contract: 'ETH',
            symbol: 'ETH'
        },
        {
            contract: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
            symbol: 'WETH'
        },
        {
            contract: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
            symbol: 'WBTC'
        },
        {
            contract: '0xdac17f958d2ee523a2206206994597c13d831ec7',
            symbol: 'USDT'
        },
        {
            contract: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
            symbol: 'USDC'
        },
        {
            contract: '0x6b175474e89094c44da98b954eedeac495271d0f',
            symbol: 'DAI'
        },
        {
            contract: '0x514910771af9ca656af840dff83e8264ecf986ca',
            symbol: 'LINK'
        }
    ],
    5: [
        {
            contract: 'ETH',
            symbol: 'ETH'
        }
    ]
}

export {whitelist}